import "./css/App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
} from "react-router-dom";
import Map1 from "./components/home/Map1";
import Signin from "./container/Signin";
import Signup from "./container/Signup";
import PropertyList from "./components/PropertyList/PropertyList";
import MyFavorite from "./components/MyFavorite/MyFavorite";
import Plot_detail from "./container/Plot_detail";
import About_us from "./components/aboutUs/AboutUs";
import ContactUs from "./components/ContactUs/ContactUs";
import Subscribe from "./components/subscribe/Subscribe";
import MyProperty from "./components/MyProperty/MyProperty";
import history from "./history";
import Header from "./components/Header/Header";
import PrivateRoutes from "./PrivateRoutes";
import { useEffect, useMemo, useState } from "react";
import Profile from "./components/Profile/Profile";
import { connect } from "react-redux";
import DataDetail from "./components/DataList/DataDetail";
import MyDataDetail from "./components/MyProperty/DataList/MyDataDetail";

function App(props) {
  // const [isAuth, setIsAuth] = useState({ token: false });

  // useMemo(() => {
  //   if (localStorage.Token) {
  //     setIsAuth({ token: true });
  //   }
  // },[localStorage]);

  let isAuth = { token: localStorage.Token };

  return (
    <>
      <Router>
        {isAuth.token ? <Header /> : <></>}
        <Routes>
          <Route path="/" exact element={<Map1 />} />
          <Route element={<PrivateRoutes isAuth={isAuth} />}>
            {/* <Route path="/signin" element={<Signin />} />
            <Route path="/signup" element={<Signup />} /> */}
            <Route
              path="/property_list"
              element={<PropertyList />}
              auth={isAuth}
            />
            <Route path="/my_favorite" element={<MyFavorite />} />
            <Route
              path="/my_property"
              element={<MyProperty />}
              auth={isAuth}
            />{" "}
            <Route path="/About_us" element={<About_us />} />
            <Route path="/contact_us" element={<ContactUs />} />
            <Route path="/subscribe" element={<Subscribe />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/plot_detail/:id" element={<DataDetail />} />
            <Route path="/my_plot_detail/:id" element={<MyDataDetail />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

const mapStateToProps = (state) => {
  return { token: state.Token };
};

export default connect(mapStateToProps)(App);
