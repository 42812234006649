import React from 'react';
import ReactWhatsapp from "react-whatsapp";

function WhatsAppMsg({
  btnTitle,
  number,
  message,
  title,
  ownerName,
  address,
  area,
  price
}) {
  const whatsAppNumber = "+" + number;
  const titleF = "Title: " + title;
  const ownerNameF = "Owner Name: " + ownerName;
  const addressF = "Address: " + address;
  const area_of_landF = "Area: " + area;
  const priceF = "Price: " + price;
  const defMessage =
    message +
    "\n\n" +
    titleF +
    "\n" +
    ownerNameF +
    "\n" +
    addressF +
    "\n" +
    area_of_landF +
    "\n" +
    priceF; ;

  return (
    <ReactWhatsapp
      className="theme-btn flex-0 whats-app-btn"
      number={whatsAppNumber}
      message={defMessage}
    >
      {btnTitle}
    </ReactWhatsapp>
  );
}

export default WhatsAppMsg