import React from 'react';
import "./common.css";

function ShareButton({points}) {
    const firstPoint = points[1];
    const secondPoint = points[0];
    const url= 'https://www.google.com/maps/search/' + firstPoint +',' + secondPoint +'/'
  return (
    <>
      <a
        href={`https://web.whatsapp.com/send?text= ${url}`}
        target="_blank"
        className="share-btn"
      >
        Share
      </a>
    </>
  );
}

export default ShareButton
