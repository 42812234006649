import React, { Component, useEffect } from "react";
import { useState } from "react";
import "./Subscribe.css";
import { getSubscriptionPlan } from "./Apis";
import PaymentSection from "./PaymentSection";
import Toaster from "../common/Toaster";

const Subscribe = () => {
  const [subPlan, setSubPlan] = useState([]);
  const [planSelected, setPlanSelected] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState([]);
    const [planToastShow, setPlanToastShow] = useState(false);

  const handleSubPlan = async () => {
    const response = await getSubscriptionPlan();
    if (response) {
      setSubPlan(response.data.payload);
    }
  };

  useEffect(() => {
    handleSubPlan();
  }, []);

  const handlePlanChange = (plan) => {
    setSelectedPlan(plan);
    setPlanSelected(true);
  };
 const openToaster = () => {
   setPlanToastShow(true);
 };
  const closeToaster = () => {
    setPlanToastShow(false);
  };

  const clearSelectedRadio = () => {
    subPlan.map((plan) => {
      const radioBtn = document.getElementById(plan.plan_name);
      radioBtn.checked = false;
    })
  }


  return (
    <div className="main-container container">
      <Toaster
        show={planToastShow}
        closeToaster={closeToaster}
        message="You have successfully subscribed."
      />
      <h3>Subscription</h3>
      <div className="row mt-4">
        {subPlan.map((plan) => {
          return (
            <div
              className="col-lg-4 col-md-8 col-sm-12 col-12 m-md-auto mt-md-4"
              key={plan.plan_name}
            >
              <input
                type="radio"
                id={plan.plan_name}
                name="subscription_plan"
                className="subscriprion_input d-none"
                onChange={() => handlePlanChange(plan)}
              />
              <label
                htmlFor={plan.plan_name}
                className={`price-card ${plan.plan_name}`}
              >
                <h4 className={`card-title ${plan.plan_name}-text`}>
                  {plan.plan_name}
                </h4>
                <p>{plan.description}</p>
                <p className="price-tag">&#8377; {plan.price}</p>
                <div className="subscribe-btn">Subscribe</div>
              </label>
            </div>
          );
        })}
      </div>
      {planSelected && (
        <PaymentSection
          selectedPlan={selectedPlan}
          setPlanSelected={setPlanSelected}
          clearSelectedRadio={clearSelectedRadio}
          openToaster={openToaster}
        />
      )}
    </div>
  );
};

export default Subscribe;
