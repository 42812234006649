import "../css/Signup.css";
import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import FilledInput from "@mui/material/FilledInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import { Navigate } from "react-router-dom";
import Loader from "../components/Loader/Loader";
import OtpInput from "react-otp-input";
import Toast from "react-bootstrap/Toast";
import Toaster from "../components/common/Toaster";
import CloseIcon from "@mui/icons-material/Close";

function Signup({ closeModal, openLoginModal }, props) {
  const initialValue = {
    email: "",
    username: "",
    mobile_number: "",
    password: "",
    re_enter_password: "",
  };
  const [values, setValues] = React.useState(initialValue);
  const [isLoading, setIsLoading] = React.useState(false);
  const [formError, setFormError] = React.useState(false);
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [show, setShow] = useState(false);
  const [emailUsed, setEmailUsed] = useState(false);
  const [usernameUsed, setUsernameUsed] = useState(false);
  const [mobileNumUsed, setMobileNumUsed] = useState(false);
  const [signUpOtp, setSignUpOtp] = useState(true);
  const [otp, setOtp] = useState("");
  const [otpError, setOTPError] = React.useState(false);
  const [otpSubmit, setOTPSubmit] = React.useState(false);
  const [otpShow, setOtpShow] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleOtpChange = (otp) => {
    setOtp(otp);
  };

  const handleCanclepop = () => {
    closeModal();
  };
  const handleOTPSubmit = (e) => {
    e.preventDefault();
    setOTPError(validateOTP(otp));
    setOTPSubmit(true);
    setOtpShow(false);
  };

  const validateOTP = (values) => {
    const errorsOtp = {};
    if (!values) {
      errorsOtp.otp = "OTP is required!";
    } else if (values.length !== 6) {
      errorsOtp.otp = "OTP is too short";
    }
    return errorsOtp;
  };

  const formValue = {
    email: values.email,
    username: values.username,
    mobile_number: values.mobile_number,
    password: values.password,
    confirm_password: values.confirm_password,
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setFormError(validate(values));
    setIsSubmit(true);
    setShow(false);
    setEmailUsed(false);
    setUsernameUsed(false);
    setMobileNumUsed(false);
  }

  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmit) {
      // setIsLoading(true);
      setLoader(true);
      axios
        .post("https://pinyard.in/api/v1/signup/", formValue)
        .then((responce) => {
          // setIsLoading(false);
          setLoader(false);
          setSignUpOtp(false);
        })
        .catch((error) => {
          const errObj = JSON.parse(error.request.response);
          setIsLoading(false);
          if (errObj.email) {
            setEmailUsed(true);
          }
          if (errObj.username) {
            setUsernameUsed(true);
          }
          if (errObj.mobile_number) {
            setMobileNumUsed(true);
          }
        });
    }
  }, [formError]);

  const validate = (values) => {
    const errors = {};
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const regexMobile =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    const regexPass = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regexEmail.test(values.email)) {
      errors.email = "This is not a valid email format!";
    }
    if (!values.username) {
      errors.username = "Username is required";
    }
    if (!values.mobile_number) {
      errors.mobile_number = "Mobile Number is required";
    } else if (!regexMobile.test(values.mobile_number)) {
      errors.mobile_number = "This is not a valid Mobile Number format!";
    }
    if (!values.password) {
      errors.password = "Password is required";
    } else if (!regexPass.test(values.password)) {
      errors.password = "This is not a valid Passsword format!";
    } else if (!values.password) {
    }
    if (!values.confirm_password) {
      errors.confirm_password = "Confirm Password is required";
    } else if (values.password !== values.confirm_password) {
      errors.confirm_password = "Confirm Password is not matched with password";
    }
    return errors;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowRePassword = () => {
    setValues({
      ...values,
      showRePassword: !values.showRePassword,
    });
  };

  const handleMouseDownRePassword = (event) => {
    event.preventDefault();
  };

  const [toastShow, setToastShow] = useState(false);
  const openToster = () => {
    setToastShow(true);
  };
  const closeToaster = () => {
    setToastShow(false);
  };

  useEffect(() => {
    if (Object.keys(otpError).length === 0 && otpSubmit) {
      // setIsLoading(true);
      setLoader(true);
      const params = {
        email: values.email,
        otp: otp,
      };
      axios
        .post(`https://pinyard.in/api/v1/account_verify/`, params)
        .then((responce) => {
          if (responce.data.success === true) {
            openToster();
            <Navigate to="/" />;
            closeModal();
            openLoginModal();
            // setIsLoading(false);
            setLoader(false);
          } else {
            setIsLoading(false);
            setOtpShow(true);
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  }, [otpError]);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="modal-card">
            <div
              style={{ float: "right", marginTop: "-26px" }}
              onClick={handleCanclepop}
            >
              <CloseIcon />
            </div>
            <img src="logo.png" className="login-logo" />
            <h2 className="login-modal-title">Signup</h2>
            <br />
            {signUpOtp ? (
              <div>
                <FormControl variant="filled" className="form-control">
                  <InputLabel
                    htmlFor="filled-adornment-email"
                    style={{ fontSize: 16 }}
                  >
                    Email
                  </InputLabel>
                  <FilledInput
                    id="filled-adornment-password"
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    style={{ fontSize: 20 }}
                  />
                  {emailUsed && (
                    <p className="errorMsg">This Email is alredy registered</p>
                  )}
                  <p className="errorMsg">{formError.email}</p>
                </FormControl>
                <br />

                <FormControl className="form-control" variant="filled">
                  <InputLabel
                    htmlFor="filled-adornment-username"
                    style={{ fontSize: 16 }}
                  >
                    Username
                  </InputLabel>
                  <FilledInput
                    id="filled-adornment-password"
                    type="text"
                    name="username"
                    value={values.username}
                    onChange={handleChange}
                    style={{ fontSize: 20 }}
                  />
                  {usernameUsed && (
                    <p className="errorMsg">This Username is alredy used</p>
                  )}
                  <p className="errorMsg">{formError.username}</p>
                </FormControl>
                <br />

                <FormControl className="form-control" variant="filled">
                  <InputLabel
                    htmlFor="filled-adornment-password"
                    style={{ fontSize: 16 }}
                  >
                    Mobile Number
                  </InputLabel>
                  <FilledInput
                    id="filled-adornment-mobile"
                    type="tel"
                    name="mobile_number"
                    value={values.mobile_number}
                    onChange={handleChange}
                    style={{ fontSize: 20 }}
                    maxlength="14"
                  />
                  {mobileNumUsed && (
                    <p className="errorMsg">
                      This Mobile Number is alredy registered
                    </p>
                  )}
                  <p className="errorMsg">{formError.mobile_number}</p>
                </FormControl>

                <br />

                <FormControl className="form-control" variant="filled">
                  <InputLabel
                    htmlFor="filled-adornment-password"
                    style={{ fontSize: 16 }}
                  >
                    Password
                  </InputLabel>
                  <FilledInput
                    id="filled-adornment-password"
                    type={values.showPassword ? "text" : "password"}
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    style={{ fontSize: 20 }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <small className="password-note">
                    <b>Note:</b> A password must contain at least eight
                    characters, at least one small and one capital alphabet, one
                    numeric value, and one unique symbol.
                  </small>
                  <p className="errorMsg">{formError.password}</p>
                </FormControl>
                <br />

                <FormControl className="form-control" variant="filled">
                  <InputLabel
                    htmlFor="filled-adornment-password"
                    style={{ fontSize: 16 }}
                  >
                    Confirm Password
                  </InputLabel>
                  <FilledInput
                    id="filled-adornment-password"
                    type={values.showRePassword ? "text" : "password"}
                    name="confirm_password"
                    value={values.confirm_password}
                    onChange={handleChange}
                    style={{ fontSize: 20 }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowRePassword}
                          onMouseDown={handleMouseDownRePassword}
                          edge="end"
                        >
                          {values.showRePassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <p className="errorMsg">{formError.confirm_password}</p>
                </FormControl>

                <br />
              </div>
            ) : (
              <div>
                <p className="text-center mb-4">
                  Please Enter OTP Sent to <b>{values.email}</b>
                </p>
                <Toast
                  onClose={() => setOtpShow(false)}
                  show={otpShow}
                  delay={3000}
                  className="mb-4 mx-auto bg-danger bg-gradient text-white text-center"
                >
                  <Toast.Body>Invalid OTP!</Toast.Body>
                </Toast>
                <div className="otp-input-wrraper mb-5">
                  <OtpInput
                    value={otp}
                    onChange={handleOtpChange}
                    numInputs={6}
                    className="otp-input"
                    type="number"
                  />
                  <p className="errorMsg" style={{ textAlign: "center" }}>
                    {otpError.otp}
                  </p>
                </div>
              </div>
            )}

            <div className="button-wrapper">
              <button className="button-common" onClick={closeModal}>
                Back to Login
              </button>
              {signUpOtp ? (
                <button className="button-common filled" onClick={handleSubmit}>
                  Signup
                </button>
              ) : (
                <button
                  className="button-common filled"
                  onClick={handleOTPSubmit}
                >
                  Verify OTP
                </button>
              )}
            </div>

            <p className="reserve-rights-content">
              Powered by <b>PinYard</b> Private Limited © 2022 All Rights
              Reserved
            </p>
          </div>
          // <div className="overlay-wraper"></div>
          {isLoading && <Loader />}
          <Toaster
            show={toastShow}
            closeToaster={closeToaster}
            message="You have successfully Registered."
          />
        </>
      )}
    </>
  );
}

export default Signup;
