import request from "../../../api";
import { endpoints } from "../../../api/endpoints";

export const handleContactOwner = async () => {
  try {
    return await request.get(`${endpoints.contactOwner}`);
  } catch (error) {
    return false;
  }
};

export const updateFevStatus = async (params) => {
  return request.post(`${endpoints.updateFevStatus}`, params);
};

export const handlePropertyStatusAPI = async (params, id) => {
  try {
    return await request.put(`${endpoints.getMyProperty}${id}/`, params);
  } catch (error) {
    return false;
  }
};

export const getPlotFullDetails = async (id) => {
  try {
    return await request.get(`${endpoints.getMyProperty}${id}/`);
  } catch (error) {
    return false;
  }
};
