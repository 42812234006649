import "../css/Signin.css";
import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import FilledInput from "@mui/material/FilledInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import Loader from "../components/Loader/Loader";
import OtpInput from "react-otp-input";
import Toast from "react-bootstrap/Toast";

function ForgotPassword({ props, signUpModal, closeModal, openToster }) {
  const initialValue = { email: "" };
  const [values, setValues] = React.useState(initialValue);
  const [isLoading, setIsLoading] = React.useState(false);
  const [formError, setFormError] = React.useState(false);
  const [otpError, setOTPError] = React.useState(false);
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [otpSubmit, setOTPSubmit] = React.useState(false);
  const [show, setShow] = useState(false);
  const [otpShow, setOtpShow] = useState(false);
  const [handleOTP, setHandleOTP] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpSuccess, setOtpSuccess] = useState(false);
  const newInitialValue = { password: "", confirm_password: "" };
  const [newValues, setNewValues] = React.useState(newInitialValue);
  const [newFormError, setNewFormError] = React.useState(false);
  const [newShow, setNewShow] = React.useState(false);
  const [isNewSubmit, setIsNewSubmit] = React.useState(false);
  const [loader, setLoader] = useState(false);

  const handleOtpChange = (otp) => {
    setOtp(otp);
  };

  const handleOTPSubmit = (e) => {
    e.preventDefault();
    setOTPError(validateOTP(otp));
    setOTPSubmit(true);
    setOtpShow(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormError(validate(values));
    setIsSubmit(true);
    setShow(false);
  };

  useEffect(() => {
    if (Object.keys(otpError).length === 0 && otpSubmit) {
      setIsLoading(true);
      setLoader(true);
      const params = {
        email: values.email,
        token: otp,
      };
      axios
        .post(`https://pinyard.in/api/v1/verifyOtp/`, params)
        .then((responce) => {
          if (responce.data.success === true) {
            setLoader(false);
            setOtpSuccess(true);
            setIsLoading(false);
          } else {
            setLoader(false);
            setIsLoading(false);
            setOtpShow(true);
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  }, [otpError]);

  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmit) {
      setLoader(true);
      setIsLoading(true);
      axios
        .post(`https://pinyard.in/api/v1/forgot_password/`, values)
        .then((responce) => {
          if (responce.data.success === true) {
            setLoader(false);
            setHandleOTP(true);
            setIsLoading(false);
          } else {
            setLoader(false);
            setIsLoading(false);
            setShow(true);
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  }, [formError]);

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format!";
    }
    return errors;
  };

  const validateOTP = (values) => {
    const errorsOtp = {};
    if (!values) {
      errorsOtp.otp = "OTP is required!";
    } else if (!values.length == 6) {
      errorsOtp.otp = "OTP is too short";
    }
    return errorsOtp;
  };

  const formNewValue = {
    password: newValues.password,
    confirm_password: newValues.confirm_password,
  };

  const handleNewChange = (event) => {
    const { name, value } = event.target;
    setNewValues({ ...newValues, [name]: value });
  };

  const handleClickShowPassword = () => {
    setNewValues({
      ...newValues,
      showPassword: !newValues.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowRePassword = () => {
    setNewValues({
      ...newValues,
      showRePassword: !newValues.showRePassword,
    });
  };

  const handleMouseDownRePassword = (event) => {
    event.preventDefault();
  };

  async function handleSubmitNewPass(e) {
    e.preventDefault();
    setNewFormError(validateNewPass(newValues));
    setIsNewSubmit(true);
    setNewShow(false);
  }

  const validateNewPass = (values) => {
    const errorsNewPass = {};
    const regexPass = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    if (!values.password) {
      errorsNewPass.password = "Password is required";
    } else if (!regexPass.test(values.password)) {
      errorsNewPass.password = "This is not a valid Password format!";
    } else if (!values.password) {
    }
    if (!values.confirm_password) {
      errorsNewPass.confirm_password = "Confirm Password is required";
    } else if (values.password !== values.confirm_password) {
      errorsNewPass.confirm_password =
        "Confirm Password is not matched with password";
    }
    return errorsNewPass;
  };

  useEffect(() => {
    if (Object.keys(newFormError).length === 0 && isNewSubmit) {
      setLoader(true);
      setIsLoading(true);
      const params = {
        email: values.email,
        token: otp,
        password: formNewValue.password,
      };
      axios
        .post("https://pinyard.in/api/v1/updatePassword/", params)
        .then((responce) => {
          // openToster();
          closeModal();
          setLoader(false);
          // setIsLoading(false);
        })
        .catch((error) => {
          setLoader(false);
          setIsLoading(false);
        });
    }
  }, [newFormError]);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="modal-card">
            <img src="logo.png" className="login-logo" />
            <h2 className="login-modal-title">Forgot Password?</h2>
            <p style={{ marginBottom: 30, textAlign: "center" }}>
              {handleOTP ? (
                <>
                  Please enter OTP send to{" "}
                  <b style={{ fontWeight: 600 }}>{values.email}</b>
                </>
              ) : (
                "You will ge an OTP to reset your Password"
              )}
            </p>
            <Toast
              onClose={() => setShow(false)}
              show={show}
              delay={3000}
              className="mb-4 mx-auto bg-danger bg-gradient text-white text-center"
            >
              <Toast.Body>Email ID not registered!</Toast.Body>
            </Toast>
            <Toast
              onClose={() => setOtpShow(false)}
              show={otpShow}
              delay={3000}
              className="mb-4 mx-auto bg-danger bg-gradient text-white text-center"
            >
              <Toast.Body>Invalid OTP!</Toast.Body>
            </Toast>
            {handleOTP ? (
              <>
                {otpSuccess ? (
                  <>
                    <FormControl className="form-control" variant="filled">
                      <InputLabel
                        htmlFor="filled-adornment-password"
                        style={{ fontSize: 16 }}
                      >
                        Password
                      </InputLabel>
                      <FilledInput
                        id="filled-adornment-password"
                        type={newValues.showPassword ? "text" : "password"}
                        name="password"
                        value={newValues.password}
                        onChange={handleNewChange}
                        style={{ fontSize: 20 }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {newValues.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      <small className="password-note">
                        <b>Note:</b> A password must contain at least eight
                        characters, at least one small and one capital alphabet,
                        one numeric value, and one unique symbol.
                      </small>
                      <p className="errorMsg">{newFormError.password}</p>
                    </FormControl>
                    <br />

                    <FormControl className="form-control" variant="filled">
                      <InputLabel
                        htmlFor="filled-adornment-password"
                        style={{ fontSize: 16 }}
                      >
                        Confirm Password
                      </InputLabel>
                      <FilledInput
                        id="filled-adornment-password"
                        type={newValues.showRePassword ? "text" : "password"}
                        name="confirm_password"
                        value={newValues.confirm_password}
                        onChange={handleNewChange}
                        style={{ fontSize: 20 }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowRePassword}
                              onMouseDown={handleMouseDownRePassword}
                              edge="end"
                            >
                              {newValues.showRePassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      <p className="errorMsg">
                        {newFormError.confirm_password}
                      </p>
                    </FormControl>

                    <br />

                    <div className="button-wrapper">
                      <button className="button-common" onClick={closeModal}>
                        Cancel
                      </button>
                      <button
                        className="button-common filled"
                        onClick={handleSubmitNewPass}
                      >
                        Submit
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="otp-input-wrraper">
                      <OtpInput
                        value={otp}
                        onChange={handleOtpChange}
                        numInputs={6}
                        className="otp-input"
                        type="number"
                      />
                      <p className="errorMsg" style={{ textAlign: "center" }}>
                        {otpError.otp}
                      </p>
                    </div>
                    <br />

                    <div className="button-wrapper">
                      <button className="button-common" onClick={closeModal}>
                        Cancel
                      </button>
                      <button
                        className="button-common filled"
                        onClick={handleOTPSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <FormControl variant="filled" className="form-control">
                  <InputLabel
                    htmlFor="filled-adornment-username"
                    style={{ fontSize: 16 }}
                  >
                    Email ID
                  </InputLabel>
                  <FilledInput
                    id="filled-adornment-password"
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    style={{ fontSize: 20 }}
                  />
                  <p className="errorMsg">{formError.email}</p>
                </FormControl>

                <br />

                <div className="button-wrapper">
                  <button className="button-common" onClick={closeModal}>
                    Cancel
                  </button>
                  <button
                    className="button-common filled"
                    onClick={handleSubmit}
                  >
                    Get OTP
                  </button>
                </div>
              </>
            )}

            <p className="reserve-rights-content">
              Powered by <b>PinYard</b> Private Limited © 2022 All Rights
              Reserved
            </p>
          </div>
          <div className="overlay-wraper"></div>
          {isLoading && <Loader />}
        </>
      )}
    </>
  );
}

export default ForgotPassword;
