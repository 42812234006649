import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'
import App from './App';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore,  compose } from 'redux';
import reduxthunk from 'redux-thunk';
import reducer from './reducer'
import { BrowserRouter } from 'react-router-dom';

//devlopment store
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const store = createStore(reducer , composeEnhancers(applyMiddleware(reduxthunk)))

//live store
const store = createStore(reducer , applyMiddleware(reduxthunk))

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
  document.getElementById('root')
);