import React, { useEffect } from 'react';
import "./DataItem.css";
import verifyIcon from "../../../../assets/image/verify-icon.svg"
import DummyImage from '../../../../assets/image/no_img.jpg';

function DataItem( { ...props } ) {
  return (
    <div
      className={`data-item-card  ${!props.verifyStatus && "unverified-card"}`}
      onClick={props.handleDetail}
    >
      <div className="map-img c-pointer" onClick={props.handleOpen}>
        {props.image ? <img src={props.image} /> : <img src={DummyImage} />}
      </div>
      <div className="detail-wrap c-pointer" onClick={props.handleOpen}>
        <p>
          <b>Title : </b> {props.title}
        </p>
        <p>
          <b>Name : </b> {props.name}
        </p>
        <p>
          <b>Address : </b> {props.address}
        </p>
        <p>
          <b>Area : </b> {props.area} {props.areaSizeType}
        </p>
        <p>
          <b>Price : </b> {props.price}
        </p>
      </div>
      <div className="action-wrap justify-content-between">
        <div className="verify-label d-flex align-items-center justify-content-end">
          <img src={verifyIcon} className={`${
              !props.verifyStatus && "unverified"
            } verify-icon me-1`} />
          <p
            className="verify-stetus m-0"
          >
            {props.verifyStatus ? "Verified" : "Unverified"}
          </p>
        </div>
        <div></div>
        {props.verifyStatus &&
        <div className="status-button d-flex">
          <input
            type="radio"
            id={`${props.id}-d`}
            name={props.id}
            checked={props.propertuStatus === "D"}
            className="d-none property-status"
            onChange={() => props.handlePropertyStatus("D", props.id)}
          />
          <label htmlFor={`${props.id}-d`}>
            <div className="single-btn d-flex justify-content-center align-items-center">
              D
            </div>
          </label>
          <input
            type="radio"
            id={`${props.id}-s`}
            name={props.id}
            className="d-none property-status"
            checked={props.propertuStatus === "S"}
            onChange={() => props.handlePropertyStatus("S", props.id)}
          />
          <label htmlFor={`${props.id}-s`}>
            <div className="single-btn d-flex justify-content-center align-items-center">
              S
            </div>
          </label>
          <input
            type="radio"
            id={`${props.id}-l`}
            name={props.id}
            className="d-none property-status"
            checked={props.propertuStatus === "L"}
            onChange={() => props.handlePropertyStatus("L", props.id)}
          />
          <label htmlFor={`${props.id}-l`}>
            <div className="single-btn d-flex justify-content-center align-items-center">
              L
            </div>
          </label>
        </div>
}
      </div>
    </div>
  );
}

export default DataItem