import React, { useState, useEffect } from "react";
import "./MyDataDetail.css";
import ClaimModal from "../../common/ClaimModal";
import Favourite from "../../common/Favourite";
import ShareButton from "../../common/ShareButton";
import DummyImage from "../../../assets/image/no_img.jpg";
import WhatsAppMsg from "../../common/WhatsAppMsg";
import { getPlotFullDetails, handlePropertyStatusAPI } from "./apis";
import { useParams } from "react-router-dom";
import BackIcon from "../../../assets/image/backIcon.svg";
import { useNavigate } from "react-router-dom";

function MyDataDetail() {
  const params = useParams();
  const { id } = params;

  const [details, setDetails] = useState("");
  const [exDetails, setExDetails] = useState("");

  useEffect(() => {
    getFullDetails(id);
    window.scrollTo(0, 0);
  }, []);

  const getFullDetails = async (id) => {
    const response = await getPlotFullDetails(id);
    if (response) {
      setExDetails(response.data);
      setDetails(response.data.plot);
    }
  };
  const navigate = useNavigate();

    const handleBack = () => {
      navigate(-1);
    };



  const handlePropertyStatus = async (status, id) => {
    const params = {
      status: status,
    };
    const response = await handlePropertyStatusAPI(params, id);
    if (response) {
      getFullDetails(id);
    }
  };
  return (
    <>
      <div className="main-container">
        <h4 className="tab-title mb-4 icon-title" onClick={() => handleBack()}>
          <img src={BackIcon} /> Property Details
        </h4>
        <div className="row property-detail-wrap">
          <div className="col-lg-6 col-md-12">
            <div className="property-detail-list">
              {details.owners_name && (
                <div className="property-list-item">
                  <p className="list-title">Owners Name</p>
                  <p className="list-detail">{details.owners_name}&nbsp;</p>
                </div>
              )}
              {details.last_owners_name && (
                <div className="property-list-item">
                  <p className="list-title">Last Owners Name</p>
                  <p className="list-detail">
                    {details.last_owners_name}&nbsp;
                  </p>
                </div>
              )}
              {details.last_transaction_date && (
                <div className="property-list-item">
                  <p className="list-title">Last Transaction Date</p>
                  <p className="list-detail">
                    {details.last_transaction_date}&nbsp;
                  </p>
                </div>
              )}
              {details.type_of_vastu && (
                <div className="property-list-item">
                  <p className="list-title">Type of Vastu</p>
                  <p className="list-detail">{details.type_of_vastu}&nbsp;</p>
                </div>
              )}
              {details.fsi && (
                <div className="property-list-item">
                  <p className="list-title">FSI</p>
                  <p className="list-detail">{details.fsi}&nbsp;</p>
                </div>
              )}
              {details.type_of_construction && (
                <div className="property-list-item">
                  <p className="list-title">Type of Constructions</p>
                  <p className="list-detail">
                    {details.type_of_construction}&nbsp;
                  </p>
                </div>
              )}
              {details.is_dispute && (
                <div className="property-list-item">
                  <p className="list-title">Dispute</p>
                  <p className="list-detail">
                    {details.is_dispute ? "Yes" : "No"}&nbsp;
                  </p>
                </div>
              )}
              {details.status && (
                <div className="property-list-item">
                  <p className="list-title">Property for</p>
                  <p className="list-detail">{details.status}&nbsp;</p>
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="short-detail-card">
              {details.image ? (
                <img src={details.image} className="location-img" />
              ) : (
                <img src={DummyImage} className="location-img" />
              )}
              <div className="detail-wrap position-relative">
                <p>
                  <b>Title : </b> {details.title ? details.title : "-"}
                </p>
                <p>
                  <b>Name : </b>{" "}
                  {details.owners_name ? details.owners_name : "-"}
                </p>
                <p>
                  <b>Address : </b> {details.city ? details.city : "-"}
                </p>
                <p>
                  <b>Area : </b>{" "}
                  {details.area_of_land ? (
                    <>
                      {details.area_of_land} {details.type_of_area_of_land}
                    </>
                  ) : (
                    "-"
                  )}
                </p>
                <p>
                  <b>Price : </b> {details.price ? details.price : "-"}
                </p>
                <p className="status"></p>
                {exDetails.is_verified && (
                  <div className="btn-wrap">
                    <div className="status-button d-flex">
                      <input
                        type="radio"
                        id={`${details.id}-d`}
                        name={details.id}
                        checked={exDetails.status === "D"}
                        className="d-none property-status"
                        onChange={() => handlePropertyStatus("D", details.id)}
                      />
                      <label htmlFor={`${details.id}-d`}>
                        <div className="single-btn d-flex justify-content-center align-items-center">
                          D
                        </div>
                      </label>
                      <input
                        type="radio"
                        id={`${details.id}-s`}
                        name={details.id}
                        className="d-none property-status"
                        checked={exDetails.status === "S"}
                        onChange={() => handlePropertyStatus("S", details.id)}
                      />
                      <label htmlFor={`${details.id}-s`}>
                        <div className="single-btn d-flex justify-content-center align-items-center">
                          S
                        </div>
                      </label>
                      <input
                        type="radio"
                        id={`${details.id}-l`}
                        name={details.id}
                        className="d-none property-status"
                        checked={exDetails.status === "L"}
                        onChange={() => handlePropertyStatus("L", details.id)}
                      />
                      <label htmlFor={`${details.id}-l`}>
                        <div className="single-btn d-flex justify-content-center align-items-center">
                          L
                        </div>
                      </label>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyDataDetail;
