import React, { Component, useState, useEffect } from "react";
import "./ContactUs.css";
import logo from "../../assets/image/logo.png";
import axios from "axios";
import Loader from "../Loader/Loader";
import { postContactDetails, getContactDetails } from "./apis";
import { initial } from "lodash";
import FilledInput from "@mui/material/FilledInput";


const ContactUs = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [formError, setFormError] = React.useState(false);
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [isSuccessMsg, setIsSuccessMsg] = React.useState(false);
  const [contactDetails, setContactDetails] = useState('')
  const [show, setShow] = useState(false);
  const user = localStorage.UserId;
  const initial = {
    user: user,
    name_of_user: "",
    email: "",
    mobile_number: "",
    message: "",
    user: ""
  };
  const [data, setData] = useState(initial);

  const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
  };

  
  const submit = (e) => {
        e.preventDefault();
        setFormError(validate(data));
        setIsSubmit(true);
        setShow(false);
  };

  
  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmit) {
      setIsLoading(true);
      const response = postContactDetails(data);
      if (response) {
        setIsSuccessMsg(true);
        setData({
          user: user,
          name_of_user: "",
          email: "",
          mobile_number: "",
          message: "",
        });
      }
      setIsLoading(false);
    }
  }, [formError]);

  const validate = (values) => {
  const errors = {};
  const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  const regexMobile =
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
  const regexPass = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
  if (!values.email) {
    errors.email = "Email is required!";
  } else if (!regexEmail.test(values.email)) {
    errors.email = "This is not a valid email format!";
  }
  if (!values.name_of_user) {
    errors.name_of_user = "Username is required";
  }
  if (!values.mobile_number) {
    errors.mobile_number = "Mobile Number is required";
  } else if (!regexMobile.test(values.mobile_number)) {
    errors.mobile_number = "This is not a valid Mobile Number format!";
  }
  if (!values.message) {
    errors.message = "Message is required";
  } 
  return errors;
  };

  useEffect(() => {
    handleContactDetail()
  },[])

  const handleContactDetail = async () => {
    const response = await getContactDetails();
    if(response) {
      setContactDetails(response.data[0])
    }
  }
  return (
    <>
      <div className="main-container container">
        <h3 className="mb-4">Contact Us</h3>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <form onSubmit={(e) => submit(e)}>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="name_of_user"
                  name="name_of_user"
                  placeholder="Name"
                  onChange={handleChange}
                  value={data.name_of_user}
                />
                {formError.name_of_user && (
                  <p className="errorMsg contactErrorr">
                    {formError.name_of_user}
                  </p>
                )}
              </div>

              <div className="form-group">
                <input
                  type="tel"
                  className="form-control"
                  id="mobile_number"
                  name="mobile_number"
                  placeholder="Contact Number"
                  onChange={handleChange}
                  value={data.mobile_number}
                />
                {formError.mobile_number && (
                  <p className="errorMsg contactErrorr ">
                    {formError.mobile_number}
                  </p>
                )}
              </div>

              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder="Email"
                  onChange={handleChange}
                  value={data.email}
                />
                {formError.email && (
                  <p className="errorMsg">{formError.email}</p>
                )}
              </div>

              <div className="form-group">
                <textarea
                  className="form-control"
                  id="message"
                  name="message"
                  rows="3"
                  placeholder="Enter Your Message"
                  onChange={handleChange}
                  value={data.message}
                ></textarea>
                {formError.message && (
                  <p className="errorMsg contactErrorr">{formError.message}</p>
                )}
              </div>
              {isSuccessMsg && (
                <div className="successMsg">Successfuly Submited!</div>
              )}
              <button type="submit" className="btn send-btn mb-4">
                SUBMIT
              </button>
            </form>
          </div>
          <div className="col-lg-6 col-md-12">
            <p>{contactDetails?.description}</p>
            <a
              className="single-contact-detail"
              href={`tel:${contactDetails?.contact}`}
            >
              <div className="contact-icon">
                <i className="fa fa-phone" aria-hidden="true"></i>
              </div>
              <div className="contact-detail">
                <h3 className="title">Contact Us</h3>
                <p className="detail">{contactDetails?.contact}</p>
              </div>
            </a>
            <a
              className="single-contact-detail"
              href={`mailto:${contactDetails?.email}`}
            >
              <div className="contact-icon">
                <i className="fa fa-envelope" aria-hidden="true"></i>
              </div>
              <div className="contact-detail">
                <h3 className="title">Email</h3>
                <p className="detail">{contactDetails?.email}</p>
              </div>
            </a>
          </div>
        </div>
      </div>

      {isLoading && <Loader />}
    </>
  );
};

export default ContactUs;
