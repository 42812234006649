import React, { useEffect, useState } from "react";
import DelConfirmationModal from "../common/DelConfirmationModal";
import AddCardForm from "./AddCardForm";
import {
  getCardDetail,
  delCardDetail,
  uploadCardDetails,
  handlePaymentApi,
} from "./Apis";
import Toaster from "../common/Toaster";
import StripeContainer from "./StripeContainer";
import Loader from "../Loader/Loader";
import { useDispatch } from "react-redux";
import { fetch_user_plan_detail } from "../../action";

function PaymentSection({
  selectedPlan,
  setPlanSelected,
  clearSelectedRadio,
  openToaster,
}) {
  const [addCardForm, setAddCardForm] = useState(false);
  const [cardDetail, setCardDetail] = useState([]);
  const [savedCards, setSavedCards] = useState([]);
  const [delConfiModal, setDelConfiModal] = useState(false);
  const [delStateData, setDelStateData] = useState([]);
  const [show, setShow] = useState(false);
  const [toastShow, setToastShow] = useState(false);
  const [selectedCard, setSelectedCard] = useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectCardError, setSelectCardError] = useState(false);

  const handleCardDetail = async () => {
    const response = await getCardDetail();
    setSavedCards(response.data);
  };

  useEffect(() => {
    handleCardDetail();
  }, []);

  const handleCardOpen = () => {
    setAddCardForm(true);
  };

  const handleCardClose = () => {
    setAddCardForm(false);
  };

  const handleDeleteCard = (card) => {
    setDelStateData(card);
    setDelConfiModal(true);
  };

  const delCard = async (card) => {
    const response = await delCardDetail(card.id);
    if (response) {
      handleCardDetail();
      setDelConfiModal(false);
    }
  };

  const closeConfModal = () => {
    setDelConfiModal(false);
  };

  const uploadNewCard = async () => {
    const params = {
      name: cardDetail.name,
      number: cardDetail.number.replace(/ /g, ""),
      exp_date: cardDetail.expiry,
      cvv: cardDetail.cvc,
    };
    if (params.name !== undefined) {
      const response = await uploadCardDetails(params);
      if (response) {
        setToastShow(true);
        handleCardDetail();
        setAddCardForm(false);
        setTimeout(() => {
          setToastShow(false);
        }, 3000);
      } else {
        setShow(true);
        setTimeout(() => {
          setShow(false);
        }, 5000);
      }
    }
  };
  const closeToaster = () => {
    setToastShow(false);
  };

    const dispatch = useDispatch();


  useEffect(() => {
    uploadNewCard();
  }, [cardDetail]);

  const handlePayment = async (cardId) => {
    if (!selectedCard.id) {
      setSelectCardError(true);
    } else {
      setSelectCardError(false);
      setIsLoading(true);
      let date = new Date().toJSON().slice(0, 10);
      const params = {
        plan: cardId,
        card: selectedCard.id,
        start_date: date,
      };
      const response = await handlePaymentApi(params);
      if (response) {
              clearSelectedRadio();
              setPlanSelected(false);
              setIsLoading(false);
              openToaster();
        dispatch(fetch_user_plan_detail(localStorage.UserId));
      }
      else {
         setIsLoading(false);
      }

    }
  };

  const handleSelectedPayment = (card) => {
    setSelectedCard(card);
  };

  return (
    <>
      <hr className="mt-5" />
      <Toaster
        show={toastShow}
        closeToaster={closeToaster}
        message="You have successfully added card."
      />
      <h3 className="mt-3">Purchase Subscription</h3>
      <StripeContainer />
      <div className="row payment-wrraper">
        <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt-3">
          <h5 className="mb-2 section-title">Payment Methods</h5>
          <div className="payment-card">
            {savedCards.length !== 0 && (
              <div className="sub-card">
                <h5>Saved Payment Methods</h5>
                {savedCards.map((card) => {
                  return (
                    <div
                      className="d-flex w-100 align-items-center single-card-item"
                      key={card.id}
                    >
                      <label
                        htmlFor={card.id}
                        className="saved-card-options d-flex justify-content-between align-items-center px-2"
                      >
                        <div className="d-flex align-items-center">
                          <input
                            type="radio"
                            name="card"
                            id={card.id}
                            onChange={() => handleSelectedPayment(card)}
                          />
                          <p className="mb-0 ms-2">
                            {card.brand} .... {card.last4}
                          </p>
                        </div>
                        <p className="mb-0">
                          Exp: {card.exp_month}/{card.exp_year}
                        </p>
                      </label>
                      <i
                        className="fa fa-trash del-icon"
                        aria-hidden="true"
                        onClick={() => handleDeleteCard(card)}
                      ></i>
                    </div>
                  );
                })}
              </div>
            )}

            <div className="sub-card">
              {addCardForm ? (
                <AddCardForm
                  handleCardClose={handleCardClose}
                  setCardDetail={setCardDetail}
                  uploadNewCard={uploadNewCard}
                  show={show}
                />
              ) : (
                <div className="button-wrapper mt-0">
                  <button
                    className="button-common filled add-card-btn"
                    onClick={handleCardOpen}
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i>
                    Add Card
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt-3">
          <h5 className="mb-2 section-title">Summary</h5>
          <div className="payment-card">
            <div className="sub-card">
              <p className="mb-0">You have selected</p>
              <h5 className="mb-0">
                <b>{selectedPlan.plan_name} Subscription Plan</b>
              </h5>
            </div>
            <div className="sub-card d-flex justify-content-between">
              <div>
                <b>{selectedPlan.plan_name}</b>
                <p className="mt-1 mb-0 discription">
                  {selectedPlan.description}
                </p>
              </div>
              <p className="mb-0">&#8377; {selectedPlan.price}</p>
            </div>
            <div className="sub-card d-flex justify-content-between total-amount">
              <b>Total you have to pay</b>
              <b>&#8377; {selectedPlan.price}</b>
            </div>
            <div className="sub-card">
              {selectCardError && (
                <p className="errorMsg text-center mb-2">
                  <b>Please select payment method!</b>
                </p>
              )}
              <div className="button-wrapper mt-0">
                <button
                  className="button-common filled"
                  onClick={() => handlePayment(selectedPlan.plan_id)}
                >
                  Proceed to Payment
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {delConfiModal && (
        <DelConfirmationModal
          delStateData={delStateData}
          delCard={delCard}
          closeConfModal={closeConfModal}
        />
      )}
      {isLoading && <Loader />}
    </>
  );
}

export default PaymentSection;
