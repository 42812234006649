import request from "../../api";
import { endpoints } from "../../api/endpoints";

export const getAboutUs = async () => {
    try {
        return await request.get(endpoints.getAboutUs)
    } catch (error) {
        return false
    }

}
