import { LOGIN } from "../../action/types";


export default (state= null, action) => {
    switch(action.type){
        case LOGIN:
            if(action.payload){
                return action.payload
            }
            else {
                return state=null
            }
        default:
            return state;
    }
}