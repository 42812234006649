import React, { useEffect, useState, useMemo } from "react";

function Search({ ...props }) {
  const [clearSearch, setClearSearch] = useState(true);

  useMemo(() => {
    if(props.searchText !== '') {
        setClearSearch(false)
    }
    else{
        setClearSearch(true)
    }
  }, [props.searchText]);

  return (
    <div className="position-relative searchbar">
      <input
        type="search"
        name="search"
        value={props.searchText}
        onChange={props.handleSearch}
        placeholder={props.placeHolder}
      />

      {clearSearch ? (
        <button className="search-button">
          <i className="fa fa-search" aria-hidden="true"></i> Search
        </button>
      ) : (
        <>
          <button className="search-button" onClick={props.handleClear}>
            <i className="fa fa-times" aria-hidden="true"></i> Clear
          </button>
        </>
      )}
    </div>
  );
}

export default Search;
