import React, { Component, component, useEffect, useState } from "react";
import DataList from "./DataList/DataList";
import {
  getMyProperty,
  handleFilterData,
  handlePropertyStatusAPI,
  getPlotFullDetails,
  updatePlotDetailsCount,
} from "./apis";
import Loader from "../Loader/Loader";
import BackIcon from "../../assets/image/backIcon.svg";
import Filter from "../common/Filter";
import NoDataFound from "../common/NoDataFound";
import { fetch_user_plan_detail } from "../../action";
import { useDispatch, useSelector } from "react-redux";
import NoPlanModal from "../common/NoPlanModal";
import CreditUseConfirmation from "../common/CreditUseConfirmation";
import { useNavigate } from "react-router-dom";
import DataLoader from "../common/DataLoader";

const MyProperty = () => {
  const [openDetail, setOpenDetail] = useState(true);
  const [listData, setListData] = useState([]);
  const [selDataDeatil, setSelDataDeatil] = useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [items, setItems] = useState([]);
  const [available, setAvailable] = useState();
  const [areaSizeType, setAreaSizeType] = useState('');
  const [vastuFilter, setVastuFilter] = useState([]);
  const [constTypeFilter, setConstTypeFilter] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [finalListData, setFinalListData] = useState([]);
  const [subscribeModal, setSubscribeModal] = useState(false);
  const [creditUseConfModal, setCreditUseConfModal] = useState(false);
  const [detailsId, setSetailsId] = useState("");
  const [modalStatus, setModalStatus] = useState("");
   const [areaMinSize, setAreaMinSize] = useState("");
    const [areaMaxSize, setAreaMaxSize] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetch_user_plan_detail(localStorage.UserId));
  }, []);

  const clickData = useSelector((state) => state.User_plan_detail);
  const getDataList = async () => {
    setIsLoading(true)
    const response = await getMyProperty(searchText);
    if (response) {
      setListData(response.data);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDataList();
  }, []);

  const [selectedData, setSelectedData] = useState([]);

  const handleItemDetail = async (id) => {
    await getFullDetails(id);
    setOpenDetail(!openDetail);
  };

  const getFullDetails = async (id) => {
    const response = await getPlotFullDetails(id);
    if (response) {
      setSelectedData(response.data);
    }
  };

  const handleDetailMain = (id) => {
    handleItemDetail(id);
    setOpenDetail(false);
  };

  const navigate = useNavigate();

  const handleDetail = async (id, status) => {
    const link = "/plot_detail/" + id;
    const myLink = "/my_plot_detail/" + id;
    const plots = clickData.visited_plot;
    if (!plots.includes(id)) {
      if (clickData.is_subscribe) {
        setCreditUseConfModal(true);
        setModalStatus(status);
        setSetailsId(id);
      } else {
        setSubscribeModal(true);
      }
    } else {
      handleDetailMain(id);
      navigate(`${status ? myLink : link}`);
    }
  };

  const handleConfirmDetail = async (id) => {
    const link = "/plot_detail/" + id;
    const myLink = "/my_plot_detail/" + id;

    setCreditUseConfModal(false);
    const params = {
      plot_id: id,
    };
    const response = await updatePlotDetailsCount(params);
    if (response) {
      dispatch(fetch_user_plan_detail(localStorage.UserId));
      handleDetailMain(id);
      navigate(`${modalStatus ? myLink : link}`);
    }
  };

  const handleBack = () => {
    setOpenDetail(!openDetail);
  };

  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    setItems(localStorage.getItem("Token"));
  }, [items]);

  const handleFilter = async (e) => {
    const vastuFilterFinal = vastuFilter.map(Number);
    const constTypeFilterFinal = constTypeFilter.map(Number);

    const params = {
      type_of_vastu: vastuFilterFinal,
      type_of_construction: constTypeFilterFinal,
      min_value: [],
      max_value: [],
      text: [],
    };

      if (
        areaSizeType &&
        (areaMinSize.length !== 0 || areaMaxSize.length !== 0)
      ) {
        params["type_of_area_of_land"] = [areaSizeType];
        if (areaMinSize.length !== 0) {
          params["min_value"] = [areaMinSize];
        }
        if (areaMaxSize.length !== 0) {
          params["max_value"] = [areaMaxSize];
        }
      }

    if (available) {
      params["is_available"] = available;
    }
    const response = await handleFilterData(params);
    if (response) {
      setFilteredData(response.data.payload);
    }
  };

  useEffect(() => {
    const finalArr = [];
    for (const element of listData) {
      for (const item of filteredData) {
        if (element.id === item.id) {
          finalArr.push(element);
        }
      }
    }
    setFinalListData(finalArr);
  }, [filteredData, listData]);

  const handlePropertyStatus = async (status, id) => {
    const params = {
      status: status,
    };
    const response = await handlePropertyStatusAPI(params, id);
    if (response) {
      getFullDetails(id);
      getDataList();
    }
  };

  const closeConfModal = () => {
    setSubscribeModal(false);
  };

  const closeCreConfModal = () => {
    setCreditUseConfModal(false);
  };

  return (
    <>
      <div className="main-container">
        {openDetail ? (
          <>
            <h4 className="tab-title mb-4">My Property List</h4>
          </>
        ) : (
          <h4 className="tab-title mb-4 icon-title" onClick={handleBack}>
            <img src={BackIcon} /> Property Details
          </h4>
        )}
        <div className="row m-0 tab-data-wrap">
          {openDetail && (
            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 p-0">
              <Filter
                handleFilter={handleFilter}
                setConstTypeFilter={setConstTypeFilter}
                constTypeFilter={constTypeFilter}
                setVastuFilter={setVastuFilter}
                vastuFilter={vastuFilter}
                setAvailable={setAvailable}
                available={available}
                setSearchText={setSearchText}
                searchText={searchText}
                getDataList={getDataList}
                areaSizeType={areaSizeType}
              setAreaSizeType={setAreaSizeType}
              setAreaMinSize={setAreaMinSize}
              areaMinSize={areaMinSize}
              setAreaMaxSize={setAreaMaxSize}
              areaMaxSize={areaMaxSize}
              />
            </div>
          )}
          <div
            className={`${
              openDetail
                ? "col-xl-9 col-lg-9 col-md-12 col-sm-12 pe-0"
                : "col-xl-12 col-lg-12 col-md-12 col-sm-12 pe-0"
            }`}
          >
            {finalListData.length ? (
              <DataList
                listData={finalListData}
                handleDetail={handleDetail}
                selDataDeatil={selDataDeatil}
                openDetail={openDetail}
                handleBack={handleBack}
                selectedData={selectedData}
                handlePropertyStatus={handlePropertyStatus}
              />
            ) : (
              <>{isLoading ? <DataLoader /> : <NoDataFound />}</>
            )}
          </div>
        </div>
      </div>
      {subscribeModal && <NoPlanModal closeConfModal={closeConfModal} />}
      {creditUseConfModal && (
        <CreditUseConfirmation
          closeConfModal={closeCreConfModal}
          detailsId={detailsId}
          handleConfirmDetail={handleConfirmDetail}
        />
      )}
    </>
  );
};

export default MyProperty;
