import React from "react";

function DelConfirmationModal({ delStateData, delCard, closeConfModal }) {
  return (
    <>
      <div className="confirmation-modal">
        <div className="delet-icon d-flex justify-content-center align-items-center my-4">
          <i className="fa fa-trash-o" aria-hidden="true"></i>
        </div>
        <div className="saved-card-options mx-auto mb-2 d-flex justify-content-between align-items-center px-2 card-details">
          <div className="d-flex align-items-center">
            <p className="mb-0 ms-2">
              {delStateData.brand} .... {delStateData.last4}
            </p>
          </div>
          |
          <p className="mb-0">
            Exp: {delStateData.exp_month}/{delStateData.exp_year}
          </p>
        </div>
        <p className="text-center">Are you sure want to Delete this card?</p>
        <div className="button-wrapper mt-4">
          <button className="button-common" onClick={closeConfModal}>
            Cancel
          </button>
          <button
            className="button-common filled"
            onClick={() => delCard(delStateData)}
          >
            Delete
          </button>
        </div>
      </div>
      <div className="overlay-wraper"></div>
    </>
  );
}

export default DelConfirmationModal;
