import request from "../../api";
import { endpoints } from "../../api/endpoints";

export const getSubscriptionPlan = async () => {
  try {
    return await request.get(`${endpoints.getSubPlan}`);
  } catch (error) {
    return false;
  }
};


export const getCardDetail = async () => {
  try {
    return await request.get(`${endpoints.paymentCard}`);
  } catch (error) {
    return false;
  }
};

export const delCardDetail = async (id) => {
  try {
    return await request.delete(`${endpoints.paymentCard}${id}/`);
  } catch (error) {
    return false;
  }
};


export const uploadCardDetails = async (params) => {
  try {
    return await request.post(`${endpoints.paymentCard}`, params);
  } catch (error) {
    return false;
  }
};

export const handlePaymentApi = async (params) => {
  try {
    return await request.post(`${endpoints.buySubscription}`, params);
  } catch (error) {
    return false;
  }
};