import React, { Component, useState, useEffect } from "react";
import DataList from "../DataList/DataList";
import {
  getFavPlotDetails,
  updateFevStatus,
  handleFilterData,
  getPlotFullDetails,
  updatePlotDetailsCount,
} from "./apis";
import Loader from "../Loader/Loader";
import Filter from "../common/Filter";
import NoDataFound from "../common/NoDataFound";
import CreditUseConfirmation from "../common/CreditUseConfirmation";
import { useDispatch, useSelector } from "react-redux";
import { fetch_user_plan_detail } from "../../action";
import NoPlanModal from "../common/NoPlanModal";
import { useNavigate } from "react-router-dom";
import DataLoader from "../common/DataLoader";

const MyFavorite = () => {
  const [openDetail, setOpenDetail] = useState(true);
  const [favListData, setFevListData] = useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [searchText, setSearchText] = useState("");
  const [available, setAvailable] = useState();
  const [areaSizeType, setAreaSizeType] = useState("");
  const [vastuFilter, setVastuFilter] = useState([]);
  const [constTypeFilter, setConstTypeFilter] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [finalListData, setFinalListData] = useState([]);
  const [subscribeModal, setSubscribeModal] = useState(false);
  const [creditUseConfModal, setCreditUseConfModal] = useState(false);
  const [detailsId, setSetailsId] = useState("");
  const [areaMinSize, setAreaMinSize] = useState("");
  const [areaMaxSize, setAreaMaxSize] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetch_user_plan_detail(localStorage.UserId));
  }, []);
  useEffect(() => {
    getFavDataList();
  }, []);

  const getFavDataList = async () => {
    setIsLoading(true);
    const response = await getFavPlotDetails(searchText);
    if (response) {
      setFevListData(response.data);
      setIsLoading(false);
    }
  };

  const clickData = useSelector((state) => state.User_plan_detail);

  const [selectedData, setSelectedData] = useState([]);

  const handleItemDetail = (id) => {
    getFullDetails(id);
  };

  const getFullDetails = async (id) => {
    const response = await getPlotFullDetails(id);
    if (response) {
      setSelectedData(response.data);
    }
  };

  const handleDetailMain = (id) => {
    handleItemDetail(id);
    setOpenDetail(false);
  };

  const navigate = useNavigate();

  const handleFavDetail = async (id) => {
    const link = "/plot_detail/" + id;

    const plots = clickData.visited_plot;
    if (!plots.includes(id)) {
      if (clickData.is_subscribe) {
        setCreditUseConfModal(true);
        setSetailsId(id);
      } else {
        setSubscribeModal(true);
      }
    } else {
      handleDetailMain(id);
      navigate(`${link}`);
    }
  };

  const handleConfirmDetail = async (id) => {
    const link = "/plot_detail/" + id;
    setCreditUseConfModal(false);
    const params = {
      plot_id: id,
    };
    const response = await updatePlotDetailsCount(params);
    if (response) {
      dispatch(fetch_user_plan_detail(localStorage.UserId));
      handleDetailMain(id);
      navigate(`${link}`);
    }
  };

  const handleBack = () => {
    setOpenDetail(true);
  };

  const handleFilter = async (e) => {
    const vastuFilterFinal = vastuFilter.map(Number);
    const constTypeFilterFinal = constTypeFilter.map(Number);

    const params = {
      type_of_vastu: vastuFilterFinal,
      type_of_construction: constTypeFilterFinal,
      min_value: [],
      max_value: [],
      text: [],
    };
    if (
      areaSizeType &&
      (areaMinSize.length !== 0 || areaMaxSize.length !== 0)
    ) {
      params["type_of_area_of_land"] = [areaSizeType];
      if (areaMinSize.length !== 0) {
        params["min_value"] = [areaMinSize];
      }
      if (areaMaxSize.length !== 0) {
        params["max_value"] = [areaMaxSize];
      }
    }
    if (available) {
      params["is_available"] = available;
    }
    const response = await handleFilterData(params);
    if (response) {
      setFilteredData(response.data.payload);
    }
  };

  useEffect(() => {
    const finalArr = [];
    for (const element of favListData) {
      for (const item of filteredData) {
        if (element.id === item.id) {
          finalArr.push(element);
        }
      }
    }
    setFinalListData(finalArr);
  }, [filteredData, favListData]);

  const handleFav = async (id, status) => {
    const params = {
      plot_id: id,
    };
    const response = await updateFevStatus(params);
    if (response) {
      handleItemDetail(id);
      getFavDataList();
    } else {
    }
  };

  const closeConfModal = () => {
    setSubscribeModal(false);
  };

  const closeCreConfModal = () => {
    setCreditUseConfModal(false);
  };

  return (
    <div className="main-container">
      <h4 className="tab-title mb-4">My Favorite Property List</h4>

      <div className="row m-0 tab-data-wrap">
        <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 p-0">
          <Filter
            handleFilter={handleFilter}
            setConstTypeFilter={setConstTypeFilter}
            constTypeFilter={constTypeFilter}
            setVastuFilter={setVastuFilter}
            vastuFilter={vastuFilter}
            setAvailable={setAvailable}
            available={available}
            setSearchText={setSearchText}
            searchText={searchText}
            getDataList={getFavDataList}
            areaSizeType={areaSizeType}
            setAreaSizeType={setAreaSizeType}
            setAreaMinSize={setAreaMinSize}
            areaMinSize={areaMinSize}
            setAreaMaxSize={setAreaMaxSize}
            areaMaxSize={areaMaxSize}
          />
        </div>
        <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12">
          {finalListData.length ? (
            <DataList
              listData={finalListData}
              handleDetail={handleFavDetail}
              openDetail={openDetail}
              handleBack={handleBack}
              selectedData={selectedData}
              handleFav={handleFav}
              handleItemDetail={handleFavDetail}
            />
          ) : (
            <>{isLoading ? <DataLoader /> : <NoDataFound />}</>
          )}
        </div>
      </div>
      {subscribeModal && <NoPlanModal closeConfModal={closeConfModal} />}
      {creditUseConfModal && (
        <CreditUseConfirmation
          closeConfModal={closeCreConfModal}
          detailsId={detailsId}
          handleConfirmDetail={handleConfirmDetail}
        />
      )}
    </div>
  );
};

export default MyFavorite;
