import React from 'react';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const PUBLIC_IC_KEY =
  "pk_test_51LFGiyFwIECqv8wWLqB8ssubiBvfRjFLqvuejvkx4ynIoMQuJtMMMIPRaW7NyXvtEVJpNmA4J30J4DYxe7tO9GQl00kr5Rze0H";

  const stripeTestPromise = loadStripe(PUBLIC_IC_KEY);

function StripeContainer() {
  return <Elements stripe={stripeTestPromise}>
     
  </Elements>;
}

export default StripeContainer