import request from "../../api";
import { endpoints } from "../../api/endpoints";

export const getProfileDetails = async (id) => {
  try {
    return await request.get(`${endpoints.getProfile}${id}/`);
  } catch (error) {
    return false;
  }
};


export const updateProfile = async (id, params) => {
  try {
    return await request.put(`${endpoints.update_profile}${id}/`, params);
  } catch (error) {
    return false;
  }
};
