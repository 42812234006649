import request from "../../api";
import { endpoints } from "../../api/endpoints";

export const getMyProperty = async (searchText) => {
  try {
    if (searchText === "") {
      return await request.get(`${endpoints.getMyProperty}`);
    } else {
      return await request.get(
        `${endpoints.getMyProperty}${"?search="}${searchText}`
      );
    }
  } catch (error) {
    return false;
  }
};


export const handleFilterData = async (params) => {
  try {
    return await request.post(endpoints.filterList, params);
  } catch (error) {
    return false;
  }
};


export const handlePropertyStatusAPI = async (params, id) => {
  try {
    return await request.put(`${endpoints.getMyProperty}${id}/`, params);
  } catch (error) {
    return false;
  }
};


export const getPlotFullDetails = async (id) => {
  try {
    return await request.get(`${endpoints.getMyProperty}${id}/`);
  } catch (error) {
    return false;
  }
};

export const updatePlotDetailsCount = async (params) => {
  try {
    return await request.post(`${endpoints.getPlotDetails}`, params);
  } catch (error) {
    return false;
  }
};