import React, { useEffect } from 'react';
import "./DataItem.css";
import Favourite from "../../common/Favourite"
import ClaimModal from '../../common/ClaimModal';
import ShareButton from '../../common/ShareButton';
import DummyImage from '../../../assets/image/no_img.jpg';

function DataItem( { ...props } ) {
 
  return (
    <div className="data-item-card" onClick={props.handleDetail}>
      <div className="map-img c-pointer" onClick={props.handleOpen}>
        {props.image ? <img src={props.image} /> : <img src={DummyImage} />}
      </div>
      <div className="detail-wrap c-pointer" onClick={props.handleOpen}>
        <p>
          <b>Title : </b> {props.title ? <>{props.title}</> : <>-</>}
        </p>
        <p>
          <b>Name : </b> {props.name ? <>{props.name}</> : <>-</>}
        </p>
        <p>
          <b>Address : </b> {props.address ? <>{props.address}</> : <>-</>}
        </p>
        <p>
          <b>Area : </b>{" "}
          {props.area ? (
            <>
              {props.area} {props.areaSizeType}
            </>
          ) : (
            <>-</>
          )}
        </p>
        <p>
          <b>Price : </b> {props.price ? <>{props.price}</> : <>-</>}
        </p>
      </div>
      <div className="action-wrap">
        <p className="status">
          {props.status ? (
            <span className="available">
              <i className="fa fa-map-marker" aria-hidden="true"></i>
            </span>
          ) : (
            <span className="notAvailable">
              <i className="fa fa-map-marker" aria-hidden="true"></i>
            </span>
          )}
        </p>
        <div className="btn-wrap">
          <div className="theme-btn bordered">
            <ShareButton points={props.sharePoint} />
          </div>
          {props.status && <ClaimModal plotId={props.id} />}
        </div>
      </div>
      <div
        className="list-favorite-icon"
        onClick={() => props.handleFav(props.id, props.favoriteStatus)}
      >
        <Favourite favoriteStatus={props.favoriteStatus} />
      </div>
    </div>
  );
}

export default DataItem