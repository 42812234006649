import "../css/Signin.css";
import React, { useEffect, useMemo, useState } from "react";
import IconButton from "@mui/material/IconButton";
import FilledInput from "@mui/material/FilledInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import Loader from "../components/Loader/Loader";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Toast from "react-bootstrap/Toast";
import { connect, useDispatch } from "react-redux";
import { login } from "../action";
import { signin } from "../action";
import { fetchUserProfile } from "../action";
import Cookies from "universal-cookie";
import CloseIcon from "@mui/icons-material/Close";

function Signin(props) {
  const initialValue = { email: "", password: "" };
  const [values, setValues] = React.useState(initialValue);
  const [isLoading, setIsLoading] = React.useState(false);
  const [formError, setFormError] = React.useState(false);
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [show, setShow] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [loader, setLoader] = useState(false);

  const cookies = new Cookies();

  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormError(validate(values));
    setIsSubmit(true);
    setShow(false);
  };

  useEffect(() => {
    setValues({
      email: cookies.get("username"),
      password: cookies.get("password"),
    });
  }, []);

  useMemo(() => {
    if (values.email && values.password) {
      setIsChecked(true);
    }
  }, [values]);

  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmit) {
      setIsLoading(true);
      setLoader(true);
      dispatch(login(values));

      axios
        .post(`https://pinyard.in/api/v1/login/`, values)
        .then((responce) => {
          if (responce.data.success === true) {
            if (isChecked) {
              cookies.set("username", values.email);
              cookies.set("password", values.password);
            } else {
              cookies.set("username", "");
              cookies.set("password", "");
            }

            localStorage.setItem("Token", responce.data.payload.token);
            localStorage.setItem("UserId", responce.data.payload.id);
            dispatch(fetchUserProfile(responce.data.payload.id));
            dispatch(signin(responce.data.payload.token));
            setLoader(false);
            props.closeModal();
            props.history.push("/");
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setShow(true);
            setLoader(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  }, [formError]);

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format!";
    }
    if (!values.password) {
      errors.password = "Password is required";
    }
    return errors;
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCheckBox = (e) => {
    setIsChecked(!isChecked);
  };
  const handleCanclepop = () => {
    props.closeModal();
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="modal-card">
            <div
              style={{ float: "right", marginTop: "-26px" }}
              onClick={handleCanclepop}
            >
              <CloseIcon />
            </div>

            <img src="logo.png" className="login-logo" />
            <h2 className="login-modal-title">Welcome</h2>
            <Toast
              onClose={() => setShow(false)}
              show={show}
              delay={3000}
              className="mb-4 mx-auto bg-danger bg-gradient text-white text-center"
            >
              <Toast.Body>Invalid Email or Password!</Toast.Body>
            </Toast>
            <FormControl variant="filled" className="form-control">
              <InputLabel
                htmlFor="filled-adornment-username"
                style={{ fontSize: 16 }}
              >
                Email
              </InputLabel>
              <FilledInput
                id="filled-adornment-password"
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                style={{ fontSize: 20 }}
              />
              <p className="errorMsg">{formError.email}</p>
            </FormControl>

            <FormControl variant="filled" className="form-control">
              <InputLabel
                htmlFor="filled-adornment-password"
                style={{ fontSize: 16 }}
              >
                Password
              </InputLabel>
              <FilledInput
                id="filled-adornment-password"
                type={values.showPassword ? "text" : "password"}
                name="password"
                value={values.password}
                onChange={handleChange}
                style={{ fontSize: 20 }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <p className="errorMsg">{formError.password}</p>
            </FormControl>
            <br />

            <div className="form-footer">
              <div className="keep-login-wrap">
                <input
                  type="checkbox"
                  id="keepMeLogin"
                  className="keepMeLogin"
                  name="keepMeLogin"
                  value={isChecked}
                  checked={isChecked}
                  onClick={handleCheckBox}
                />
                <label htmlFor="keepMeLogin" className="keep-login-label">
                  Keep me login
                </label>
              </div>
              <div
                className="forgot-password"
                onClick={props.handleForgotPassModal}
              >
                Forgot Password?
              </div>
            </div>
            <div className="button-wrapper">
              <button className="button-common" onClick={props.signUpModal}>
                Signup
              </button>
              <button className="button-common filled" onClick={handleSubmit}>
                Login
              </button>
            </div>

            <p className="reserve-rights-content">
              Powered by <b>PinYard</b> Private Limited © 2022 All Rights
              Reserved
            </p>
          </div>
          <div className="overlay-wraper"></div>
          {isLoading && <Loader />}
        </>
      )}
    </>
  );
}

export default Signin;
