import request from "../../api";
import { endpoints } from "../../api/endpoints";

export const postContactDetails = async (params) => {
  try {
    return await request.post(endpoints.postContactDetails, params);
  } catch (error) {
    return false;
  }
};


export const getContactDetails = async (id) => {
  try {
    return await request.get(`${endpoints.contactDetail}`);
  } catch (error) {
    return false;
  }
};
