import { FETCH_USER_PLAN } from "../../action/types";


export default (state= [], action) => {
    switch(action.type){
        case FETCH_USER_PLAN:
            return {...state, ...action.payload };
        default:
            return state;
    }
}