import React from "react";
import { useNavigate } from "react-router-dom";

function NoPlanModal({ delStateData, delCard, closeConfModal }) {


  const navigate = useNavigate();
  const handleSubscribe = () => {
      navigate("/subscribe");
  }

  return (
    <>
      <div className="confirmation-modal">
        <div className="delet-icon d-flex justify-content-center align-items-center my-4">
          <i className="fa fa-info" aria-hidden="true"></i>
        </div>
        <div className="saved-card-options mx-auto mb-2 d-flex justify-content-between align-items-center px-2 card-details"></div>
        <p className="text-center">
          Choose the right subscription plan to see details.
        </p>
        <div className="button-wrapper mt-4">
          <button className="button-common" onClick={closeConfModal}>
            Cancel
          </button>
          <button
            className="button-common filled"
            onClick={() => handleSubscribe()}
          >
            Subscribe
          </button>
        </div>
      </div>
      <div className="overlay-wraper"></div>
    </>
  );
}

export default NoPlanModal;
