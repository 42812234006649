import request from "../../api";
import { endpoints } from "../../api/endpoints";

export const handleContactOwner = async () => {
  try {
    return await request.get(`${endpoints.contactOwner}`);
  } catch (error) {
    return false;
  }
};

export const updateFevStatus = async (params) => {
  return request.post(`${endpoints.updateFevStatus}`, params);
};

export const getPlotFullDetails = async (id) => {
  try {
    return await request.get(`${endpoints.getPlotDetails}${id}/`);
  } catch (error) {
    return false;
  }
};  