import React, { useEffect, useState } from "react";
import "./Profile.css";
import DummyProfileImg from "../../assets/image/DummyProfile.jpg";
import { getProfileDetails, updateProfile } from "./apis";
import Loader from "../Loader/Loader";
import Toaster from "../common/Toaster";
import { connect } from "react-redux";
import { fetchUserProfile } from "../../action";
import { PropaneSharp } from "@mui/icons-material";

function Profile(props) {
  const initialValues = {
    username: "",
    // email: "",
    mobile_number: "",
    first_name: "",
    last_name:"",
  };
  const [isLoading, setIsLoading] = React.useState(false);
  const [formError, setFormError] = React.useState(false);
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [show, setShow] = useState(false);
  const [profileData, setProfileData] = useState("");
  const [data, setData] = useState(initialValues);
  const [editEnable, setEditEnable] = useState(true);

  const getProfile = async () => {
    const id = localStorage.UserId;
    const response = await getProfileDetails(id);
    if (response) {
      localStorage.setItem("Email", response.data.email);
      localStorage.setItem("UserName", response.data.username);
      localStorage.setItem("FirstName", response.data.first_name);
      localStorage.setItem("LastName", response.data.last_name);
      setProfileData(response.data);
      setData({
        username: response.data.username,
        // email: response.data.email,
        mobile_number: response.data.mobile_number,
        first_name: response.data.first_name,
        last_name: response.data.last_name,
      });
    }
  };

  const updateAPI = async (id, params) => {
    const response = await updateProfile(id, params);
    if (response) {
      props.fetchUserProfile(localStorage.UserId)
      openToster();
      getProfile();
    }
  };


  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmit) {
      setIsLoading(true);
      const id = localStorage.UserId;
      const params = data;
      updateAPI(id, params);
      setIsLoading(false);
      setEditEnable(true);
    }
  }, [formError]);

  useEffect(() => {
    getProfile();
  }, []);



  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handelSave = (e) => {
    e.preventDefault();
    setFormError(validate(data));
    setIsSubmit(true);
    setShow(false);
  };

  const validate = (values) => {
    const errors = {};
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const regexMobile =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    // if (!values.email) {
    //   errors.email = "Email is required!";
    // } else if (!regexEmail.test(values.email)) {
    //   errors.email = "This is not a valid email format!";
    // }
    if (!values.username) {
      errors.name_of_user = "Username is required";
    }
    if (!values.mobile_number) {
      // errors.mobile_number = "Mobile Number is required";
    } else if (!regexMobile.test(values.mobile_number)) {
      errors.mobile_number = "This is not a valid Mobile Number format!";
    }
    return errors;
  };

  const handleEdit = () => {
    setEditEnable(false);
  };
  const [toastShow, setToastShow] = useState(false);
  const openToster =() => {
    setToastShow(true);
  }
  const closeToaster = () => {
    setToastShow(false)
  }
  return (
    <>
      <div className="main-container">
        <Toaster
          show={toastShow}
          closeToaster={closeToaster}
          message="You have successfully changed profile details."
        />
        <div className="profile-img-container">
          <img src={DummyProfileImg} className="profile-img" />
        </div>
        <h4 className="name-title">
          {profileData.first_name}&nbsp;
          {profileData.last_name}
        </h4>
        <div className="profile-detail">
          <div className="single-item">
            <label>Email ID</label>
            <p className="info">{profileData.email}</p>
            {/* {editEnable ? (
            <p className="info">{profileData.email}</p>
          ) : (
            <>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                onChange={handleChange}
                value={data.email}
              />
              {formError.email && (
                <p className="errorMsg contactErrorr text-center">
                  {formError.email}
                </p>
              )}
            </>
          )} */}
          </div>
          {editEnable ? null : (
            <>
              <div className="single-item">
                <>
                  <label>First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="first_name"
                    name="first_name"
                    onChange={handleChange}
                    value={data.first_name}
                  />
                </>
              </div>
              <div className="single-item">
                <>
                  <label>Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="last_name"
                    name="last_name"
                    onChange={handleChange}
                    value={data.last_name}
                  />
                </>
              </div>
            </>
          )}
          <div className="single-item">
            <label>Username</label>
            {editEnable ? (
              <p className="info">{profileData.username}</p>
            ) : (
              <>
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  name="username"
                  onChange={handleChange}
                  value={data.username}
                />
                {formError.username && (
                  <p className="errorMsg contactErrorr text-center">
                    {formError.username}
                  </p>
                )}
              </>
            )}
          </div>

          <div className="single-item">
            <label>Mobile No.</label>
            {editEnable ? (
              <p className="info">{profileData.mobile_number}</p>
            ) : (
              <>
                <input
                  type="tel"
                  className="form-control"
                  id="mobile_number"
                  name="mobile_number"
                  onChange={handleChange}
                  value={data.mobile_number}
                />
                {formError.mobile_number && (
                  <p className="errorMsg contactErrorr text-center">
                    {formError.mobile_number}
                  </p>
                )}
              </>
            )}
          </div>
        </div>
        <div className="profile-btn-group mx-auto">
          {editEnable ? (
            <button className="theme-btn" onClick={handleEdit}>
              Edit
            </button>
          ) : (
            <button className="theme-btn" onClick={handelSave}>
              Save
            </button>
          )}
        </div>
      </div>
      {isLoading && <Loader />}
    </>
  );
}

const mapStateToProps = (state) => {
  return {userData: state.UserProfile}
}

export default connect(mapStateToProps, {fetchUserProfile})(Profile);
