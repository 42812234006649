import React from 'react';
import "./common.css"

function favourite({ ...props }) {
  return (
    <div
      className={`favorite-btn ${props.favoriteStatus ? "follow" : "unfollow"}`}
    >
      <i className="fa fa-star" aria-hidden="true"></i>
    </div>
  );
}

export default favourite