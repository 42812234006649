import request from "../../api";
import { endpoints } from "../../api/endpoints";

export const changePassword = async (id, params) => {
  try {
    return await request.put(`${endpoints.change_password}${id}/`, params);
  } catch (error) {
    return false;
  }
};
