import property_detail_api from "./api/property_detail_api";
import API from "./api/API";
import { FETCH_PLOAT, FETCH_DATA, FETCH_USER_PROFILE, LOGIN, FETCH_LOGIN, FETCH_USER_PLAN } from "./types";
import { endpoints } from "../api/endpoints";
import request from "../api";


export const signin = (token) => {
    return {type: LOGIN, payload: token }
}

export const login = (value) => async dispatch => {
    const response = await API.post(`/login/`, value)
    dispatch({type: FETCH_LOGIN, payload: response.data})
}

export const fetchPlot = (id) => async dispatch => {
    const response = await property_detail_api.get(`/plotdetail_table/${id}/`, {
        headers: {authorization: `Token ${localStorage.getItem("Token")}`}
    });

    dispatch({type: FETCH_PLOAT, payload: response.data})
}
export const fetchData  = () => async dispatch => {
    const response = await API.get('/get_plotdetail/' , {
        headers: { authorization: `Token ${localStorage.getItem("Token")}` }
    })
    dispatch({ type: FETCH_DATA, payload: response.data})
}


export const fetchUserProfile  = (id) => async dispatch => {
    const response = await API.get(`/user_profile/${id}/`, {
        headers: { authorization: `Token ${localStorage.getItem("Token")}` }
    })
    dispatch({ type: FETCH_USER_PROFILE, payload: response.data})
}


export const fetch_user_plan_detail = (userId) => async dispatch =>  {
    const response = await API.get(`/user_plan_details/${userId}/`,  {
        headers: { authorization: `Token ${localStorage.getItem("Token")}` }
    })
    dispatch({ type: FETCH_USER_PLAN, payload: response.data})
}


