import { FETCH_LOGIN } from "../../action/types";



export default (state= '', action) => {
    switch(action.type){
        case FETCH_LOGIN:
            return {...state, ...action.payload };
        default:
            return state;
    }
}