import React from "react";
import '../css/Plot_detail.css';
import { fetchPlot } from "../action";
import { connect, Connect } from "react-redux";

class Plot_detail extends React.Component {

componentDidMount(){
    const { id } = this.props.match.params;
     this.props.fetchPlot(id)
}

renderList(){
    const plot = this.props.plot_data;
    const style = {
        border: "solid 0px black",
        backgroundColor: "#C99577",
        opacity: "1",
        color: "white",
    }
    return (
        <div className="panel panel-default">
            <nav id="nav-title" className="navbar-brand col-lg-12 align_heading" role="navigation" >
                <div className="navbar-brand col-lg-12 align_heading" style={style}>
                    Form
                    <br />
                    Plot_detail
                </div>
            </nav>
            <div className="panel-body" style={{overflowX: "scroll"}}>
                <div id="disp_tab">
                    <table className="table table-bordered table-striped table-condensed table-responsive">
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>2</td>
                                <td>3</td>
                                <td>4</td>
                                <td>5</td>
                                <td>6</td>
                                <td>7</td>
                                <td>8</td>
                                <td>9</td>
                                <td>10</td>
                                <td>11</td>
                                <td>12</td>
                                <td>13</td>
                                <td>14</td>
                                <td>15</td>
                                <td>16</td>
                                <td>17</td>
                                <td>18</td>
                                <td>19</td>
                                <td>20</td>
                                <td>21</td>
                                <td>22</td>
                                <td>23</td>

                            </tr>
                            <tr style={{fontWeight: "bold" , color: "#65402E"}}>
                                <td>CASE_NO/NO</td>
                                <td>Owner Name:</td>
                                <td>Tenure</td>
                                <td>Block No./ R.S.No.</td>
                                <td>Original Plot No</td>
                                <td>Area in Sq. Mt.</td>
                                <td>O.P. Rate</td>
                                <td>Without References To Value Of Structure</td>
                                <td>Inclusive Of Structure</td>
                                <td>Final Plot No.</td>
                                <td>Area in Sq. Mt</td>
                                <td>S.F. Rate</td>
                                <td>Without References To Value of Structure</td>
                                <td>Inclusive Of Structure:</td>
                                <td>Final Rate</td>
                                <td>Without References To Value Of Structure</td>
                                <td>Inclusive Of Structure</td>
                                <td>Contribution(+), Compansation(-) Under Section-80(column 9b-column 6b)</td>
                                <td>Increment (Section-78) (Column 10a-Column 9a)</td>
                                <td>Contribution(Section-79) 50% of column-12:</td>
                                <td>Addition To(+) Or Deduction On From(-) Contribution to be made under other section</td>
                                <td>Net demand from(+) or by (-)owner being the addition of column-11,13,14</td>
                                <td>Remarks</td>
                            </tr>
                            <tr style={{fontWeight: "bold" , color: "#65402E"}}>
                                <td>{plot.case_no}</td>
                                <td>{plot.owners_name}</td>
                                <td>{plot.tenure}</td>
                                <td>{plot.block_no}</td>
                                <td>{plot.org_plot_no}</td>
                                <td>{plot.org_area_sqft}</td>
                                <td>{plot.org_op_rate}</td>
                                <td>{plot.org_val_of_structure}</td>
                                <td>{plot.org_inclusive_of_structure}</td>
                                <td>{plot.final_plot_no}</td>
                                <td>{plot.final_area_sqft}</td>
                                <td>{plot.final_sf_rate}</td>
                                <td>{plot.final_val_of_structure}</td>
                                <td>{plot.final_inclusive_of_structure}</td>
                                <td>{plot.final_rate}</td>
                                <td>{plot.final_rate_value_of_structure}</td>
                                <td>{plot.final_rate_inclusive_of_structure}</td>
                                <td>{plot.contribution_compansation_section80}</td>
                                <td>{plot.increment}</td>
                                <td>{plot.contribution_section79}</td>
                                <td>{plot.addition_deduction}</td>
                                <td>{plot.demand}</td>
                                <td>{plot.remarks}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )

}
    render(){
        return(
            <div className="container-fluid">
            {this.renderList()}
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return { plot_data: state.ploat_detail }
};

export default connect(mapStateToProps , { fetchPlot })(Plot_detail);