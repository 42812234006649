import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import profileImg from "../../assets/image/profile-img.png";
import HeaderLogo from "../HeaderLogo/HeaderLogo";
import Profile from "../Profile/Profile";
import Loader from "../Loader/Loader";
import { getProfileDetails } from "./Apis";
import { useMemo } from "react";
import ForgotModal from "../ForgotModal/ForgotModal";
import { connect, useDispatch, useSelector } from "react-redux";
import { PropaneSharp } from "@mui/icons-material";
import { signin } from "../../action";
import { fetchUserProfile, fetch_user_plan_detail } from "../../action";
import { NavLink } from "react-router-dom";

const Header = (props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [profileData, setProfileData] = useState("");
  const [changePassModal, setChangePassModal] = useState(false);

  const userData = useSelector((state) => state.UserProfile);
  const clickData = useSelector((state) => state.User_plan_detail);
  const dispatch = useDispatch();

  const handleLogout = () => {
    localStorage.clear();
    dispatch(signin(null));
    props.history.push("/");
  };

  useEffect(() => {
    getProfile();
    dispatch(fetchUserProfile(localStorage.UserId));
    dispatch(signin(localStorage.Token));
    dispatch(fetch_user_plan_detail(localStorage.UserId));
  }, []);

  const getProfile = async () => {
    const id = localStorage.UserId;
    setIsLoading(true);
    const response = await getProfileDetails(id);
    if (response) {
      setProfileData(response.data);
    }
    setIsLoading(false);
  };

  const handleChangePassword = () => {
    setChangePassModal(true);
  };

  const closeChangePassModal = () => {
    setChangePassModal(false);
  };

  return (
    <>
      <nav className="navbar navbar-expand-xl header-wrapper py-0 px-0">
        <div className="container-fluid h-100 innner-header">
          <div className="header-main-logo">
            <HeaderLogo />
          </div>
          <div
            className="collapse navbar-collapse toggler-data-wrapper"
            id="navbarSupportedContent"
          >
            <div
              className="close"
              id="navbarSupportedContent"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            ></div>
            <div className="mobile-header-logo">
              <HeaderLogo />
            </div>
            <ul className="navbar-nav mb-2 mb-lg-0 mr-auto navbar-list-wrapper">
              <li className="nav-item">
                <NavLink to="/" activeClassName="active">
                  <div
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                  >
                    Home
                  </div>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink activeClassName="active" to="property_list">
                  <div
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                  >
                    Property List
                  </div>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink activeClassName="active" to="/my_favorite">
                  <div
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                  >
                    My Favorite
                  </div>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink activeClassName="active" to="/my_property">
                  <div
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                  >
                    My Property
                  </div>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink activeClassName="active" to="/About_us">
                  <div
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                  >
                    About Us
                  </div>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink activeClassName="active" to="contact_us">
                  <div
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                  >
                    Contact Us
                  </div>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink activeClassName="active" to="/subscribe">
                  <div
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                  >
                    Subscribe
                  </div>
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="d-flex align-items-center h-100">
            {clickData.is_subscribe && (
              <div className="nav-item px-3 me-0 text-center credit-count d-flex align-items-center">
                <div>
                  <small>Credits</small>
                  <br />
                  <b>
                    {clickData.click_count}/{clickData.total_count}
                  </b>
                </div>
              </div>
            )}
            <ul className="dropdown-menu-header list-unstyled ms-3 mb-0 me-3 me-xl-0">
              <li className="nav-item dropdown">
                <Link
                  to=""
                  className="nav-link dropdown-toggle"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div className="proile-dropdown" type="button">
                    <div className="profile-img-wrapper">
                      <img src={profileImg} />
                    </div>
                    <div className="user-name-wrapper">
                      <p>{userData.username}</p>
                    </div>
                  </div>
                </Link>
                <ul className="list-unstyled dropdown-menu proile-dropdown-menu">
                  <li className="item px-3 proile-dropdown p-2 pb-1">
                    <Link to="/profile" className="w-100">
                      <div className="inner-item">
                        <div className="profile-img-wrapper">
                          <img src={profileImg} />
                        </div>
                        <div className="user-name-wrapper">
                          <p>
                            {userData.first_name}&nbsp;
                            {userData.last_name}
                          </p>
                          <span>{profileData.email}</span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <hr className="m-0" />
                  <div className="py-2">
                    <ul className="list-unstyled item-list mb-0">
                      <li className="item px-3">
                        <div onClick={handleChangePassword}>
                          <div className="inner-item">
                            <i className="fa fa-lock" aria-hidden="true"></i>
                            <p>Change Password</p>
                          </div>
                        </div>
                      </li>
                      <li className="item px-3">
                        <Link to="/" onClick={handleLogout}>
                          <div className="inner-item">
                            <i
                              className="fa fa-sign-out"
                              aria-hidden="true"
                            ></i>
                            <p>Log Out</p>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </ul>
              </li>
            </ul>
          </div>
          <div
            className="collapse mobile-menu-overlay"
            id="navbarSupportedContent"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          ></div>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
      </nav>
      {changePassModal && <ForgotModal closeModal={closeChangePassModal} />}
    </>
  );
};

export default Header;
