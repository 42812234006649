import React, { useEffect, useMemo, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import { handleConstructionType, handleVastuTypeList } from "./apis";
import e from "cors";
import Search from "../common/Search";
import "./common.css";

function Filter({
  handleFilter,
  setConstTypeFilter,
  constTypeFilter,
  setVastuFilter,
  vastuFilter,
  setAvailable,
  available,
  setSearchText,
  searchText,
  getDataList,
  setAreaSizeType,
  setAreaMinSize,
  areaMinSize,
  setAreaMaxSize,
  areaMaxSize,
}) {
  const [constructionTypes, setConstructionTypes] = useState([]);
  const [typeOfVastu, setTypeOfVastu] = useState([]);
  const [collapsedFilter, setCollapsedFilter] = useState(true);

  useEffect(() => {
    getConstructionTypeList();
    getVastuTypeList();
  }, []);

  const getConstructionTypeList = async () => {
    const res = await handleConstructionType();
    if (res) {
      setConstructionTypes(res.data);
    }
  };

  const getVastuTypeList = async () => {
    const res = await handleVastuTypeList();
    if (res) {
      setTypeOfVastu(res.data);
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    getDataList();
  }, [searchText]);

  const handleChangeAvailable = (e) => {
    const checked = e.target.checked;
    setAvailable(checked);
  };

  const handleChangeVastu = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;
    if (checked) {
      setVastuFilter([...vastuFilter, value]);
    } else {
      setVastuFilter(vastuFilter.filter((e) => e !== value));
    }
  };

  const handleChangeConstruction = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;
    if (checked) {
      setConstTypeFilter([...constTypeFilter, value]);
    } else {
      setConstTypeFilter(constTypeFilter.filter((e) => e !== value));
    }
  };

  const handleAreaSizeType = (e) => {
    const areaSizetype = e.target.value;
    setAreaSizeType(areaSizetype);
    setAreaMinSize("");
    setAreaMaxSize("");
  };
  const handleMinValue = (e) => {
    setAreaMinSize(e.target.value);
  };
  const handleMaxValue = (e) => {
    setAreaMaxSize(e.target.value);
  };

  useEffect(() => {
    handleFilter();
  }, [constTypeFilter, vastuFilter, available, areaMaxSize, areaMinSize]);

  const handleFilterOpen = () => {
    setCollapsedFilter(!collapsedFilter);
  };

  return (
    <>
      <div className="main-filter-title d-none d-lg-block">Filter</div>
      <Search
        placeHolder="Search by Owner Name, City, Area"
        handleSearch={handleSearch}
        searchText={searchText}
      />
      <button
        className="main-filter-title-btn d-flex d-lg-none"
        onClick={handleFilterOpen}
      >
        Filter
      </button>
      <div
        className={`${
          collapsedFilter ? "d-none d-lg-block" : "d-block d-lg-block"
        }`}
      >
        <div className="single-filter-item mb-3">
          <h6 className="filter-title">Status</h6>
          <div className="ps-3">
            <FormControlLabel
              control={
                <Checkbox onChange={handleChangeAvailable} name="available" />
              }
              label="Available"
            />
          </div>
        </div>

        <div className="single-filter-item mb-3 area-filter">
          <h6 className="filter-title">Area of Land</h6>
          <div className="ps-3">
            <FormControl>
              <RadioGroup name="area-size-buttons-group">
                <div className="radio-item">
                  <input
                    type="radio"
                    value="SQ. FT."
                    label="Square Foot"
                    name="area-radio"
                    id="square_foot"
                    onChange={handleAreaSizeType}
                  />
                  <label htmlFor="square_foot">Square Foot</label>
                  <div className="valueInput">
                    <input
                      type="number"
                      placeholder="Min"
                      value={areaMinSize}
                      onChange={handleMinValue}
                    ></input>
                    -
                    <input
                      type="number"
                      placeholder="Max"
                      value={areaMaxSize}
                      onChange={handleMaxValue}
                    ></input>
                  </div>
                </div>
                <div className="radio-item">
                  <input
                    type="radio"
                    value="SQ. YD."
                    label="square yard"
                    name="area-radio"
                    id="square_yard"
                    onChange={handleAreaSizeType}
                  />
                  <label htmlFor="square_yard">Square Yard</label>
                  <div className="valueInput">
                    <input
                      type="number"
                      placeholder="Min"
                      value={areaMinSize}
                      onChange={handleMinValue}
                    ></input>
                    -
                    <input
                      type="number"
                      placeholder="Max"
                      value={areaMaxSize}
                      onChange={handleMaxValue}
                    ></input>
                  </div>
                </div>
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        <div className="single-filter-item mb-3">
          <h6 className="filter-title">Type of Construction</h6>
          <div className="ps-3">
            {constructionTypes.map((type) => (
              <div key={type.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChangeConstruction}
                      value={type.id}
                      name={`${type.id}`}
                    />
                  }
                  label={type.name_of_construction}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="single-filter-item mb-3">
          <h6 className="filter-title">Type of Vastu</h6>
          <div className="ps-3">
            {typeOfVastu.map((type) => (
              <div key={type.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChangeVastu}
                      value={type.id}
                      name={`${type.id}`}
                    />
                  }
                  label={type.name_of_vastu}
                />
                <br />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Filter;
