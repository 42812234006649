import React, { useEffect, useState } from "react";
import "./ForgotModal.css";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FilledInput from "@mui/material/FilledInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { changePassword } from "./Api";
import Toast from "react-bootstrap/Toast";
import Toaster from "../common/Toaster";

function ForgotModal({ closeModal }) {
  const initialValue = {
    old_password: "",
    new_password: "",
    re_enter_password: "",
  };
  const [values, setValues] = useState(initialValue);
  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = React.useState(false);
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [showErr, setShowErr] = useState(false);
  const [show, setShow] = useState(false);
  const [toastShow, setToastShow] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  const handleChangePassword = async () => {
    const id = localStorage.UserId;
    const params = {
      old_password: values.old_password,
      new_password: values.new_password,
    };
    const response = await changePassword(id, params);
    if (response) {
      setShow(false);
      openToster();
      closeModal();
    } else {
      setShow(true);
    }
  };

  const openToster = () => {
    setToastShow(true);
  };
  const closeToaster = () => {
    setToastShow(false);
  };

  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmit) {
      handleChangePassword();
    }
  }, [formError]);

  const validate = (values) => {
    const errors = {};
    const regexPass = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    if (!values.old_password) {
      errors.old_password = "Old Password is required";
    }
    if (!values.new_password) {
      errors.new_password = "New Password is required";
    } else if (!regexPass.test(values.new_password)) {
      errors.new_password = "This is not a valid New Passsword format!";
    } else if (!values.new_password) {
    }
    if (!values.confirm_password) {
      errors.confirm_password = "Confirm New Password is required";
    } else if (values.new_password !== values.confirm_password) {
      errors.confirm_password =
        "Confirm New Password is not matched with password";
    }
    return errors;
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setFormError(validate(values));
    setIsSubmit(true);
    setShowErr(false);
  }

  const handleClickShowOldPassword = () => {
    setValues({
      ...values,
      showOldPassword: !values.showOldPassword,
    });
  };

  const handleMouseDownOldPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowRePassword = () => {
    setValues({
      ...values,
      showRePassword: !values.showRePassword,
    });
  };

  const handleMouseDownRePassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Toaster
        show={toastShow}
        closeToaster={closeToaster}
        message="You have successfully changed Password."
      />

      <div className="forgot-modal">
        <div className="modal-header">
          <h5>Change Password</h5>
          <div onClick={closeModal}>
            <i className="fa fa-times close-btn" aria-hidden="true"></i>
          </div>
        </div>
        <div className="modal-body">
          <Toast
            onClose={() => setShow(false)}
            show={show}
            delay={3000}
            className="mb-4 mx-auto bg-danger bg-gradient text-white text-center"
          >
            <Toast.Body>You've entered the wrong Old Password</Toast.Body>
          </Toast>
          <FormControl className="form-control" variant="filled">
            <InputLabel htmlFor="old_password" style={{ fontSize: 16 }}>
              Old Password
            </InputLabel>
            <FilledInput
              id="old_password"
              type={values.showOldPassword ? "text" : "password"}
              name="old_password"
              value={values.old_password}
              onChange={handleChange}
              style={{ fontSize: 20 }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowOldPassword}
                    onMouseDown={handleMouseDownOldPassword}
                    edge="end"
                  >
                    {values.showOldPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
            <p className="errorMsg">{formError.old_password}</p>
          </FormControl>
          <br />

          <FormControl className="form-control mt-2" variant="filled">
            <InputLabel htmlFor="new_password" style={{ fontSize: 16 }}>
              New Password
            </InputLabel>
            <FilledInput
              id="new_password"
              type={values.showPassword ? "text" : "password"}
              name="new_password"
              value={values.new_password}
              onChange={handleChange}
              style={{ fontSize: 20 }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <small className="password-note">
              <b>Note:</b> A password must contain at least eight characters, at
              least one small and one capital alphabet, one numeric value, and
              one unique symbol.
            </small>
            <p className="errorMsg">{formError.new_password}</p>
          </FormControl>
          <br />

          <FormControl className="form-control mt-2" variant="filled">
            <InputLabel
              htmlFor="filled-adornment-password"
              style={{ fontSize: 16 }}
            >
              Confirm New Password
            </InputLabel>
            <FilledInput
              id="filled-adornment-password"
              type={values.showRePassword ? "text" : "password"}
              name="confirm_password"
              value={values.confirm_password}
              onChange={handleChange}
              style={{ fontSize: 20 }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowRePassword}
                    onMouseDown={handleMouseDownRePassword}
                    edge="end"
                  >
                    {values.showRePassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <p className="errorMsg">{formError.confirm_password}</p>
          </FormControl>
        </div>
        <div className="modal-footer button-wrapper">
          <button className="button-common" onClick={closeModal}>
            Cancel
          </button>
          <button className="button-common filled" onClick={handleSubmit}>
            Change Password
          </button>
        </div>
      </div>
      <div className="overlay-wraper"></div>
    </>
  );
}

export default ForgotModal;
