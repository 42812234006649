import React, { useEffect, useState } from "react";
import "./DataDetail.css";
import ClaimModal from "../common/ClaimModal";
import Favourite from "../common/Favourite";
import ShareButton from "../common/ShareButton";
import DummyImage from "../../assets/image/no_img.jpg";
import WhatsAppMsg from "../common/WhatsAppMsg";
import {
  getPlotFullDetails,
  handleContactOwner,
  updateFevStatus,
} from "./apis";
import { useParams } from "react-router-dom";
import BackIcon from "../../assets/image/backIcon.svg";
import { useNavigate } from "react-router-dom";

function DataDetail({ ...props }) {
  const params = useParams();
  const { id } = params;

  const [details, setDetails] = useState("");
  const [sharePoints, setSharePoints] = useState([])

  useEffect(() => {
    getFullDetails(id);
    window.scrollTo(0, 0);
  }, []);


  const getFullDetails = async (id) => {
    const response = await getPlotFullDetails(id);
    if (response) {
      setDetails(response.data);
    }
  };



  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };

  const [ownerData, setOwnerData] = useState([]);
 

  useEffect(() => {
    handleContact();
  }, []);

  const handleContact = async () => {
    const response = await handleContactOwner();
    if (response) {
      // setSharePoints(await extracrMapPoint(response.data.point));
      setOwnerData(response.data[0]);
    }
  };

  const handleFav = async (id) => {
    const params = {
      plot_id: id,
    };
    const response = await updateFevStatus(params);
    if (response) {
      getFullDetails();
    } else {
    }
  };

    const extracrMapPoint = (cords) => {
      const point = cords;
      const pointString = point.substring(17, point.length - 1);
      const pointArray = pointString.split(" ");
      return pointArray;
    };

  return (
    <div className="main-container">
      <h4 className="tab-title mb-4 icon-title" onClick={() => handleBack()}>
        <img src={BackIcon} /> Property Details
      </h4>
      <div className="row property-detail-wrap">
        <div className="col-lg-6 col-md-12">
          <div className="property-detail-list">
            {details.owners_name && (
              <div className="property-list-item">
                <p className="list-title">Owners Name</p>
                <p className="list-detail">{details.owners_name}&nbsp;</p>
              </div>
            )}
            {details.last_owners_name && (
              <div className="property-list-item">
                <p className="list-title">Last Owners Name</p>
                <p className="list-detail">{details.last_owners_name}&nbsp;</p>
              </div>
            )}
            {details.last_transaction_date && (
              <div className="property-list-item">
                <p className="list-title">Last Transaction Date</p>
                <p className="list-detail">
                  {details.last_transaction_date}&nbsp;
                </p>
              </div>
            )}
            {details.type_of_vastu && (
              <div className="property-list-item">
                <p className="list-title">Type of Vastu</p>
                <p className="list-detail">{details.type_of_vastu}&nbsp;</p>
              </div>
            )}
            {details.fsi && (
              <div className="property-list-item">
                <p className="list-title">FSI</p>
                <p className="list-detail">{details.fsi}&nbsp;</p>
              </div>
            )}
            {details.type_of_construction && (
              <div className="property-list-item">
                <p className="list-title">Type of Constructions</p>
                <p className="list-detail">
                  {details.type_of_construction}&nbsp;
                </p>
              </div>
            )}
            {details.owners_name && (
              <div className="property-list-item">
                <p className="list-title">Dispute</p>
                <p className="list-detail">
                  {details.is_dispute ? "Yes" : "No"}&nbsp;
                </p>
              </div>
            )}
            {props.status && (
              <div className="property-list-item">
                <p className="list-title">Property for</p>
                <p className="list-detail">{props.status}&nbsp;</p>
              </div>
            )}
          </div>
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="short-detail-card">
            {details.image ? (
              <img src={details.image} className="location-img" />
            ) : (
              <img src={DummyImage} className="location-img" />
            )}
            <div className="detail-wrap position-relative">
              <p>
                <b>Title : </b> {details.title}
              </p>
              <p>
                <b>Name : </b> {details.owners_name}
              </p>
              <p>
                <b>Address : </b> {details.city}
              </p>
              <p>
                <b>Area : </b> {details.area_of_land}{" "}
                {details.type_of_area_of_land}
              </p>
              <p>
                <b>Price : </b> {details.price}
              </p>
              <p className="status">
                {details.is_available ? (
                  <span className="available">
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                  </span>
                ) : (
                  <span className="notAvailable">
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                  </span>
                )}
              </p>
              <div className="btn-wrap">
                {/* <div className="theme-btn bordered flex-0">
                  <ShareButton points={sharePoints} />
                </div> */}
                {details.is_available && <ClaimModal plotId={details.id} />}
                {ownerData.length !== 0 && (
                  <WhatsAppMsg
                    btnTitle="Contact To Owner"
                    number={ownerData.contact}
                    message={ownerData.message}
                    title={details.title}
                    ownerName={details.owners_name}
                    address={details.address}
                    area={details.area}
                    price={details.price}
                  />
                )}
              </div>
              <div
                className="list-favorite-icon"
                onClick={() => handleFav(details.id)}
              >
                <Favourite favoriteStatus={details.is_favorite} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DataDetail;
