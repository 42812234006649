import axios from "axios";
import { urls } from "../config";

const request = axios.create({
    baseURL: urls.apiBaseUrl+'/',
});

// Add a request interceptor
request.interceptors.request.use(function (config) {
    // Do something before request is sent
    config.headers.authorization = `Token ${localStorage.getItem("Token")}`
    // console.log("request",config);
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
request.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log("response", response);
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // console.log("error", error);
    return Promise.reject(error);
});

export default request