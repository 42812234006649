import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import FormControl from "@mui/material/FormControl";
import FilledInput from "@mui/material/FilledInput";
import axios from "axios";
import { handleClaimData } from "./apis"
import fileUploadedImage from "../../assets/image/file_uploaded.png"

function ClaimModal({ plotId }) {
  const initialValue = {
    doc1: "",
    doc2: "",
    doc3: "",
    doc4: "",
    doc5: "",
    doc6: "",
    plot: plotId,
    user: Number(localStorage.UserId),
  };
  const [values, setValues] = React.useState(initialValue);
  const [isLoading, setIsLoading] = React.useState(false);
  const [formError, setFormError] = React.useState(false);
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(true);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleChange = (e) => {
    const name = e.target.name;
    setValues({ ...values, [name]: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormError(validate(values));
    setIsSubmit(true);
    setShow(false);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.doc1) {
      errors.doc1 = "Required!";
    }
    if (!values.doc2) {
      errors.doc2 = "Required!";
    }
    if (!values.doc3) {
      errors.doc3 = "Required!";
    }
    if (!values.doc4) {
      errors.doc4 = "Required!";
    }
    if (!values.doc5) {
      errors.doc5 = "Required!";
    }
    if (!values.doc6) {
      errors.doc6 = "Required!";
    }
    return errors;
  };

  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmit) {
      setIsLoading(true);
      const response = handleClaimData(values);
      if (response) {
        setSuccessMessage(false);
      }
    }
  }, [formError]);



  return (
    <div>
      <button className="theme-btn" onClick={handleShow}>
        Claim
      </button>

      <Modal show={showModal} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="tab-title mb-0">Upload Document</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: 0 }}>
          {successMessage ? (
            <>
              <div className="single-doc-item d-flex justify-content-between align-items-center">
                <label className="mb-0" style={{ fontWeight: "bold" }}>
                  Adhar Card{" "}
                  {values.doc1 && (
                    <span style={{ fontWeight: "normal" }}>
                      - {values.doc1.name}
                    </span>
                  )}
                </label>
                <FormControl
                  variant="filled"
                  className="form-control"
                  style={{ display: "none", margin: 0 }}
                >
                  <FilledInput
                    id="doc-upload1"
                    type="file"
                    name="doc1"
                    // value={values.doc1}
                    onChange={handleChange}
                    style={{ display: "none" }}
                  />
                </FormControl>
                {!values.doc1 && (
                  <p className="errorMsg cliam-reqired">{formError.doc1}</p>
                )}
                <label
                  htmlFor="doc-upload1"
                  className="theme-btn bordered text-center"
                >
                  Upload
                </label>
              </div>

              <div className="single-doc-item d-flex justify-content-between align-items-center">
                <label className="mb-0" style={{ fontWeight: "bold" }}>
                  No-objection certificates (NOC){" "}
                  {values.doc2 && (
                    <span style={{ fontWeight: "normal" }}>
                      - {values.doc2.name}
                    </span>
                  )}
                </label>
                <FormControl
                  variant="filled"
                  className="form-control"
                  style={{ display: "none", margin: 0 }}
                >
                  <FilledInput
                    id="doc-upload2"
                    type="file"
                    name="doc2"
                    // value={values.doc2}
                    onChange={handleChange}
                    style={{ display: "none" }}
                  />
                </FormControl>
                {!values.doc2 && (
                  <p className="errorMsg cliam-reqired">{formError.doc2}</p>
                )}
                <label
                  htmlFor="doc-upload2"
                  className="theme-btn bordered text-center"
                >
                  Upload
                </label>
              </div>
              <div className="single-doc-item d-flex justify-content-between align-items-center">
                <label className="mb-0" style={{ fontWeight: "bold" }}>
                  Voter ID{" "}
                  {values.doc3 && (
                    <span style={{ fontWeight: "normal" }}>
                      - {values.doc3.name}
                    </span>
                  )}
                </label>
                <FormControl
                  variant="filled"
                  className="form-control"
                  style={{ display: "none", margin: 0 }}
                >
                  <FilledInput
                    id="doc-upload3"
                    type="file"
                    name="doc3"
                    // value={values.doc3}
                    onChange={handleChange}
                    style={{ display: "none" }}
                  />
                </FormControl>
                {!values.doc3 && (
                  <p className="errorMsg cliam-reqired">{formError.doc3}</p>
                )}
                <label
                  htmlFor="doc-upload3"
                  className="theme-btn bordered text-center"
                >
                  Upload
                </label>
              </div>
              <div className="single-doc-item d-flex justify-content-between align-items-center">
                <label className="mb-0" style={{ fontWeight: "bold" }}>
                  7/12 Utara{" "}
                  {values.doc4 && (
                    <span style={{ fontWeight: "normal" }}>
                      - {values.doc4.name}
                    </span>
                  )}
                </label>
                <FormControl
                  variant="filled"
                  className="form-control"
                  style={{ display: "none", margin: 0 }}
                >
                  <FilledInput
                    id="doc-upload4"
                    type="file"
                    name="doc4"
                    // value={values.doc4}
                    onChange={handleChange}
                    style={{ display: "none" }}
                  />
                </FormControl>
                {!values.doc4 && (
                  <p className="errorMsg cliam-reqired">{formError.doc4}</p>
                )}
                <label
                  htmlFor="doc-upload4"
                  className="theme-btn bordered text-center"
                >
                  Upload
                </label>
              </div>
              <div className="single-doc-item d-flex justify-content-between align-items-center">
                <label className="mb-0" style={{ fontWeight: "bold" }}>
                  Pan Card{" "}
                  {values.doc5 && (
                    <span style={{ fontWeight: "normal" }}>
                      - {values.doc5.name}
                    </span>
                  )}
                </label>
                <FormControl
                  variant="filled"
                  className="form-control"
                  style={{ display: "none", margin: 0 }}
                >
                  <FilledInput
                    id="doc-upload5"
                    type="file"
                    name="doc5"
                    // value={values.doc5}
                    onChange={handleChange}
                    style={{ display: "none" }}
                  />
                </FormControl>
                {!values.doc5 && (
                  <p className="errorMsg cliam-reqired">{formError.doc5}</p>
                )}
                <label
                  htmlFor="doc-upload5"
                  className="theme-btn bordered text-center"
                >
                  Upload
                </label>
              </div>
              <div className="single-doc-item d-flex justify-content-between align-items-center">
                <label className="mb-0" style={{ fontWeight: "bold" }}>
                  Power of Attorney{" "}
                  {values.doc6 && (
                    <span style={{ fontWeight: "normal" }}>
                      - {values.doc6.name}
                    </span>
                  )}
                </label>
                <FormControl
                  variant="filled"
                  className="form-control"
                  style={{ display: "none", margin: 0 }}
                >
                  <FilledInput
                    id="doc-upload6"
                    type="file"
                    name="doc6"
                    // value={values.doc6}
                    onChange={handleChange}
                    style={{ display: "none" }}
                  />
                </FormControl>
                {!values.doc6 && (
                  <p className="errorMsg cliam-reqired">{formError.doc6}</p>
                )}
                <label
                  htmlFor="doc-upload6"
                  className="theme-btn bordered text-center"
                >
                  Upload
                </label>
              </div>
            </>
          ) : (
            <>
              <img
                src={fileUploadedImage}
                alt=""
                className="fileuploaded-Image"
              />
              <div className="fileUpload-text">
                <h6>Your Files Are</h6>
                <h4>Uploaded Successfully!</h4>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="theme-btn bordered" onClick={handleClose}>
            Close
          </button>
          {successMessage && (
            <button className="theme-btn" onClick={handleSubmit}>
              Submit
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ClaimModal;
