import React from "react";
import "./HeaderLogo.css";
import { Link } from "react-router-dom";

function HeaderLogo() {
  return (
    <Link to="/" className="navbar-brand p-0 d-flex">
      <div data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">
        <img src="logo.png" className="header-logo" />
      </div>
    </Link>
  );
}

export default HeaderLogo;
