export const endpoints = {
  getPlotDetails: "get_plotdetail/",
  getPlotDetailsTable: "plotdetail_table",
  getAboutUs: "aboutus/",
  getFavProperty: "favorite_property/",
  updateFevStatus: "favorite_property/",
  getMyProperty: "my_property/",
  postContactDetails: "contactus/",
  postClaimDocs: "document/",
  getConstructionType: "get_costruction_type/",
  getVastuTypeList: "get_vastu_type/",
  filterList: "filter_map/",
  getProfile: "user_profile/",
  contactOwner: "contact_owner/",
  update_profile: "update_profile/",
  change_password: "change_password/",
  getSubPlan: "plan_detail/",
  paymentCard: "card/",
  verifytAccount: "account_verify/",
  buySubscription: "buy_subscription/",
  contactDetail: "contact_us_detail/",
};
