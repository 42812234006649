import React, { useState, useEffect } from "react";
import "./DataList.css";
import { map } from "lodash";
import DataItem from "./DataItem/DataItem";
import { Link } from "react-router-dom";
import MyDataDetail from "./MyDataDetail";
import axios from "axios";
import { fetchData } from "../../../action";
import { connect } from "react-redux";
import {handleContactOwner} from "./apis"

const DataList = (
  {
    listData,
    handleDetail,
    openDetail,
    handleBack,
    selectedData,
    handlePropertyStatus
  },
  props
) => {
  const [ownerData, setOwnerData] = useState([]);
  const extracrMapPoint = (cords) => {
    const point = cords;
    const pointString = point.substring(17, point.length - 1);
    const pointArray = pointString.split(" ");
    return pointArray;
  };

  useEffect(() => {
    handleContact();
  }, []);

  const handleContact = async () => {
    const response = await handleContactOwner();
    if (response) {
      setOwnerData(response.data);
    }
  };

  return (
    <>

          {listData.map((list) => {
            return (
              <div key={list.plot.id}>
                <DataItem
                  handleOpen={() => handleDetail(list.id, list.is_verified)}
                  title={list.plot.title}
                  name={list.plot.owners_name}
                  address={list.plot.city}
                  area={list.plot.area_of_land}
                  price={list.plot.price}
                  status={list.plot.is_available}
                  image={list.plot.image}
                  id={list.plot.id}
                  propertuStatus={list.status}
                  verifyStatus={list.is_verified}
                  areaSizeType={list.plot.type_of_area_of_land}
                  handlePropertyStatus={handlePropertyStatus}
                  sharePoint={extracrMapPoint(list.plot.point)}
                />
              </div>
            );
          })}
        
    </>
  );
};

export default DataList;
