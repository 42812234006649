import React from 'react';
import LoaderImg from "../../assets/image/loader.gif";
import "./Loader.css"

function Loader() {
  return (
    <div className='loader-conteiner'>
          {/* <img src={LoaderImg} /> */}
      <span className="loader-95"> </span>
    </div>
  )
}

export default Loader