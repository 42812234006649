import React, { useState, useEffect } from "react";
import "./DataList.css";
import DataItem from "./DataItem/DataItem";
import DataDetail from "./DataDetail";
import {handleContactOwner} from "./apis";

const DataList = (
  {
    listData,
    openDetail,
    handleBack,
    handleFav,
    selectedData,
    handleItemDetail,
  },
  props
) => {
  const [ownerData, setOwnerData] = useState([]);
  const extracrMapPoint = (cords) => {
    const point = cords;
    const pointString = point.substring(17, point.length - 1);
    const pointArray = pointString.split(" ");
    return pointArray;
  };

  useEffect(() => {
    handleContact();
  }, []);

  const handleContact = async () => {
    const response = await handleContactOwner();
    if (response) {
      setOwnerData(response.data, "owner");
    }
  };
  

  return (
    <>
     
          {listData.map((list) => {
            return (
              <div key={list.id}>
                <DataItem
                  handleOpen={() => handleItemDetail(list.id)}
                  title={list.title}
                  name={list.owners_name}
                  address={list.city}
                  area={list.area_of_land}
                  price={list.price}
                  status={list.is_available}
                  image={list.image}
                  areaSizeType={list.type_of_area_of_land}
                  favoriteStatus={list.is_favorite}
                  id={list.id}
                  handleFav={handleFav}
                  sharePoint={extracrMapPoint(list.point)}
                />
              </div>
            );
          })}
        
          
      
    </>
  );
};

export default DataList;
