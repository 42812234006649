import request from "../../api";
import { endpoints } from "../../api/endpoints";
import axios from "axios";

export const handleClaimData = async (params) => {
  try {

    var formData = new FormData();
    var userId = params.user;
    formData.append("user", userId);
    var plot = params.plot;
    formData.append("plot", plot);
    var imagefile1 = params.doc1;
    formData.append("doc1", imagefile1);
    var imagefile2 = params.doc2;
    formData.append("doc2", imagefile2);
    var imagefile3 = params.doc3;
    formData.append("doc3", imagefile3);
    var imagefile4 = params.doc4;
    formData.append("doc4", imagefile4);
    var imagefile5 = params.doc5;
    formData.append("doc5", imagefile5);
    var imagefile6 = params.doc6;
    formData.append("doc6", imagefile6);
    var headers = {
      'Content-Type': 'multipart/form-data',
      'authorization': `Token ${localStorage.getItem("Token")}`,
      'Access-Control-Allow-Origin': '*'
    };
    var response = await axios.post(
      "https://beta.pinyard.in/api/v1/document/",
      formData,
      {
        headers: headers,
      }
    );
    // return await request.post(endpoints.postClaimDocs, params);
  } catch (error) {
    return false;
  }
};

export const handleConstructionType = async () => {
  try {
    return await request.get(`${endpoints.getConstructionType}`);
  } catch (error) {
    return false;
  }
};

export const handleVastuTypeList = async () => {
  try {
    return await request.get(`${endpoints.getVastuTypeList}`);
  } catch (error) {
    return false;
  }
};

