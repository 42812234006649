import { FETCH_USER_PROFILE } from "../../action/types";



export default (state= [], action) => {
    switch(action.type){
        case FETCH_USER_PROFILE:
            return {...state, ...action.payload };
        default:
            return state;
    }
}