import React from 'react'
import NoDataImg from "../../assets/image/no_data.png";

function NoDataFound() {
  return (
    <div className="no-data-container">
      <img src={NoDataImg} className="no-data-img" />
      <h4 className="no-data-text">No Data Found</h4>
    </div>
  );
}

export default NoDataFound