import { combineReducers } from "redux";
import ploat_detail_reducer from "./fetchAction/ploat_detail_reducer";
import userProfile from "./fetchAction/userProfile";
import login_reducer from "./fetchAction/login_reducer";
import signin_reducer from "./fetchAction/signin_reducer";
import user_plan_detail from "./fetchAction/user_plan_detail";

export default combineReducers({
    ploat_detail: ploat_detail_reducer,
    UserProfile: userProfile,
    Token: login_reducer,
    Login_DATA: signin_reducer,
    User_plan_detail: user_plan_detail
})